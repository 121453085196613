@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.wrapper {
  display: grid;
  grid-template-areas: 'editor cluster-switcher .';
  gap: 20px;
  justify-content: flex-start;
  align-items: end;
  width: 100%;
  max-width: none;


  &-menu {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    width: 100%;
    gap: 40px;
  }

  &-logo {
    display: flex;
    position: relative;
    right: 8%;
    height: 46px;

    @include lower-tablet {
      grid-template-areas: "filters view-swither editor";
      background-color: pink!important;

      &-filters {
        display: inline-block;
      }
  
      &-view-swither {
        display: inline-block;
        width: 36px;
      }

      &-cluster-swither {
        display: none;
      }
    }

  }

  @include lower-tablet {
    justify-content: space-between;
    
    &-logo {
      grid-template-areas: "filters view-swither editor";

      &-filters {
        display: inline-block;
      }

      &-view-swither {
        display: inline-block;
      }

      &-cluster-swither {
        display: none;
      }
    }
  }
}

.slide-container {
  z-index: 999999;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  width: 44px;
  height: 96px;

  background-color: var(--surface-front);

  @media all and (max-width: 1599px) {
    width: 36px;
  }
}

.slider {
  transform: rotate(270deg);

  width: 72px;
  height: 4px;

  background: var(--surface-back);
  border-radius: 2px;
  outline: none;

  transition: var(--transition-default-duration);
  transition: opacity var(--transition-default-duration);

  appearance: none;
}

.slider:hover {
  opacity: 1;
}

.slider::-webkit-slider-thumb {
  cursor: pointer;

  width: 16px;
  height: 16px;

  background: var(--gray-800);
  border-radius: 50%;

  transition: var(--transition-default-duration) background;

  appearance: none;

  &:hover {
    background: var(--interactive-hover);
  }
}

.slider::-moz-range-thumb {
  cursor: pointer;

  width: 16px;
  height: 16px;

  background: var(--gray-800);
  border: none;
  border-radius: 50%;

  transition: var(--transition-default-duration) background;

  &:hover {
    background: var(--interactive-hover);
  }
}

@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";


.lines-item {
  border-radius: 12px;
  background-color: var(--surface-front);
  position: relative;
  display: flex;
  flex-direction: column;
  width: 47%;
  min-width: 7vw;

  border-bottom: 2px solid var(--surface-front);

  box-shadow: 
    var(--shadow-default-desktop-x) 
    var(--shadow-default-desktop-y)
    var(--shadow-default-desktop-blur)
    var(--shadow-default-desktop-color);

  &_active {
    border-bottom: 2px solid var(--interactive-default);
  }

  &__image {
    display: block;
    position: relative;
    cursor: pointer;
    width: 100%;
    height: 12vh;
    object-fit: contain;
    background-color: var(--gray-400);
    border-radius: 8px;
    background-size: cover;
    background-position: center;

    &-overlay {
      width: 100%;
      height: 100%;
      background-color: $surface-blackout;
      border-radius: 8px 8px 0 0;
      opacity: 0;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      color: var(--gray-50);
      transition: opacity .2s ease-out;
    }
  }

  &:hover {
    .lines-item__image-overlay {
      opacity: 1;
    }
  }

  @include lower-tablet-landscape {
    padding-bottom: 6px;

    &__image {
      min-height: 98px;
    }
  }

  @include lower-mobile {
    width: 100%; 
  }
}

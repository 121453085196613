@import "variables";

@font-face {
  font-family: '#{$icomoon-font-family}';
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xpg59r');
  src:  url('#{$icomoon-font-path}/#{$icomoon-font-family}.eot?xpg59r#iefix') format('embedded-opentype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.ttf?xpg59r') format('truetype'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.woff?xpg59r') format('woff'),
    url('#{$icomoon-font-path}/#{$icomoon-font-family}.svg?xpg59r##{$icomoon-font-family}') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

i {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: '#{$icomoon-font-family}' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-aerial-view {
  &:before {
    content: $icon-aerial-view; 
  }
}
.icon-aeropanorama {
  &:before {
    content: $icon-aeropanorama; 
  }
}
.icon-aim {
  &:before {
    content: $icon-aim; 
  }
}
.icon-arrow-1-down {
  &:before {
    content: $icon-arrow-1-down; 
  }
}
.icon-arrow-1-left {
  &:before {
    content: $icon-arrow-1-left; 
  }
}
.icon-arrow-1-right {
  &:before {
    content: $icon-arrow-1-right; 
  }
}
.icon-arrow-1-up {
  &:before {
    content: $icon-arrow-1-up; 
  }
}
.icon-arrow-2-down {
  &:before {
    content: $icon-arrow-2-down; 
  }
}
.icon-arrow-2-left {
  &:before {
    content: $icon-arrow-2-left; 
  }
}
.icon-arrow-2-right {
  &:before {
    content: $icon-arrow-2-right; 
  }
}
.icon-arrow-2-up {
  &:before {
    content: $icon-arrow-2-up; 
  }
}
.icon-arrow-3-left {
  &:before {
    content: $icon-arrow-3-left; 
  }
}
.icon-arrow-3-right {
  &:before {
    content: $icon-arrow-3-right; 
  }
}
.icon-backward {
  &:before {
    content: $icon-backward; 
  }
}
.icon-calendar {
  &:before {
    content: $icon-calendar; 
  }
}
.icon-camera {
  &:before {
    content: $icon-camera; 
  }
}
.icon-close-1 {
  &:before {
    content: $icon-close-1; 
  }
}
.icon-close-2 {
  &:before {
    content: $icon-close-2; 
  }
}
.icon-close-3 {
  &:before {
    content: $icon-close-3; 
  }
}
.icon-comand {
  &:before {
    content: $icon-comand; 
  }
}
.icon-cult {
  &:before {
    content: $icon-cult; 
  }
}
.icon-display {
  &:before {
    content: $icon-display; 
  }
}
.icon-download {
  &:before {
    content: $icon-download; 
  }
}
.icon-edit-1 {
  &:before {
    content: $icon-edit-1; 
  }
}
.icon-edit-2 {
  &:before {
    content: $icon-edit-2; 
  }
}
.icon-filter {
  &:before {
    content: $icon-filter; 
  }
}
.icon-forward {
  &:before {
    content: $icon-forward; 
  }
}
.icon-help {
  &:before {
    content: $icon-help; 
  }
}
.icon-hide {
  &:before {
    content: $icon-hide; 
  }
}
.icon-info {
  &:before {
    content: $icon-info; 
  }
}
.icon-link {
  &:before {
    content: $icon-link; 
  }
}
.icon-list {
  &:before {
    content: $icon-list; 
  }
}
.icon-lock {
  &:before {
    content: $icon-lock; 
  }
}
.icon-log-out {
  &:before {
    content: $icon-log-out; 
  }
}
.icon-map {
  &:before {
    content: $icon-map; 
  }
}
.icon-map-pin {
  &:before {
    content: $icon-map-pin; 
  }
}
.icon-message {
  &:before {
    content: $icon-message; 
  }
}
.icon-minus {
  &:before {
    content: $icon-minus; 
  }
}
.icon-more-1 {
  &:before {
    content: $icon-more-1; 
  }
}
.icon-more-2 {
  &:before {
    content: $icon-more-2; 
  }
}
.icon-notification {
  &:before {
    content: $icon-notification; 
  }
}
.icon-object {
  &:before {
    content: $icon-object; 
  }
}
.icon-objects {
  &:before {
    content: $icon-objects; 
  }
}
.icon-open-1 {
  &:before {
    content: $icon-open-1; 
  }
}
.icon-open-2 {
  &:before {
    content: $icon-open-2; 
  }
}
.icon-panorama {
  &:before {
    content: $icon-panorama; 
  }
}
.icon-password {
  &:before {
    content: $icon-password; 
  }
}
.icon-pause {
  &:before {
    content: $icon-pause; 
  }
}
.icon-phone {
  &:before {
    content: $icon-phone; 
  }
}
.icon-play {
  &:before {
    content: $icon-play; 
  }
}
.icon-plus {
  &:before {
    content: $icon-plus; 
  }
}
.icon-privacy-policy {
  &:before {
    content: $icon-privacy-policy; 
  }
}
.icon-read {
  &:before {
    content: $icon-read; 
  }
}
.icon-save {
  &:before {
    content: $icon-save; 
  }
}
.icon-search {
  &:before {
    content: $icon-search; 
  }
}
.icon-share {
  &:before {
    content: $icon-share; 
  }
}
.icon-show {
  &:before {
    content: $icon-show; 
  }
}
.icon-star {
  &:before {
    content: $icon-star; 
  }
}
.icon-table {
  &:before {
    content: $icon-table; 
  }
}
.icon-trashcan {
  &:before {
    content: $icon-trashcan; 
  }
}
.icon-turn-left {
  &:before {
    content: $icon-turn-left; 
  }
}
.icon-turn-right {
  &:before {
    content: $icon-turn-right; 
  }
}
.icon-under-construction {
  &:before {
    content: $icon-under-construction; 
  }
}
.icon-unlock {
  &:before {
    content: $icon-unlock; 
  }
}
.icon-user {
  &:before {
    content: $icon-user; 
  }
}
.icon-video {
  &:before {
    content: $icon-video; 
  }
}
.icon-volume-down {
  &:before {
    content: $icon-volume-down; 
  }
}
.icon-volume-off {
  &:before {
    content: $icon-volume-off; 
  }
}
.icon-volume-up {
  &:before {
    content: $icon-volume-up; 
  }
}


.progress-icon{
  width: 28px;
  animation: loading-spinner 1s linear infinite;
}

@keyframes loading-spinner {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

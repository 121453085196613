.map {
  &-wrapper {
    width: 100%;
    height: 100%;

    & canvas {
      outline: none;
    }

    & details {
      display: none;
    }
  }
}

@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.date-picker {
  &-wrapper {
    display: flex;
    gap: 5px;

    &-trigger {
      &-container {
        display: flex;
        flex-direction: row;
  
        &-button {
          min-width: 40px;
          max-width: 40px;
        }
      }
    }
  }
  &__buttons-container {
    &_inner {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      min-width: 300px;
      flex-direction: row;
      .date-picker-month-select,
      .date-picker-year-select {
        cursor: pointer;
        padding: 4px;
        padding-right: 25px;
        outline: none;
        background: var(--surface-front);
        border: none;
        font-family: var(--button-desktop-default-font-family);
        font-weight: var(--button-desktop-default-font-weight);
        line-height: var(--button-desktop-default-line-height);
        font-size: var(--button-desktop-default-font-size);
        letter-spacing: var(--button-desktop-default-letter-spacing);
        display: flex;
        justify-content: flex-start;
        :focus{
          outline: none;
        }
      }
      option {
        font-family: var(--text-desktop-x3-5-weight-1-font-family);
        font-weight: var(--text-desktop-x3-5-weight-1-font-weight);
        line-height: var(--text-desktop-x3-5-weight-1-line-height);
        font-size: var(--text-desktop-x3-5-weight-1-font-size);
        letter-spacing: var(--text-desktop-x3-5-weight-1-letter-spacing);
        padding: 8px 12px;
        background-color: var(--surface-front);
        color: var(--gray-900);
      }
    }
  }

  &__button-mobile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;

    &_first-title {
      font-style: normal;
      font-weight: var(--caption-mobile-x3-25-weight-1-font-weight);
      font-size: var(--caption-mobile-x3-25-weight-1-font-size);
      line-height: var(--caption-mobile-x3-25-weight-1-line-height);
    }
    &_second-title {
      font-style: normal;
      font-weight: var(--caption-mobile-x2-75-weight-1-font-weight);
      font-size: var(--caption-mobile-x2-75-weight-1-font-size);
      line-height: var(--caption-mobile-x2-75-weight-1-line-height);
    }
  }

  @include lower-tablet {
    &__buttons-container {
      &_inner {
        align-items: center;
        
        &-date {
          margin: 0;
          font-size: var(--text-desktop-x4-weight-1-font-size);
          font-weight: var(--text-desktop-x4-weight-1-font-weight);
          line-height: var(--text-desktop-x4-weight-1-line-height);
          color: var(--gray-900);
        }
        &-arrow-button {
          width: fit-content;
        }
        .date-picker-month-select,
      .date-picker-year-select {
        font-family: var(--button-mobile-default-font-family);
        font-weight: var(--button-mobile-default-font-weight);
        line-height: var(--button-mobile-default-line-height);
        font-size: var(--button-mobile-default-font-size);
        letter-spacing: var(--button-mobile-default-letter-spacing);
      }

      option {
        font-family: var(--text-mobile-x3-5-weight-1-font-family);
        font-weight: var(--text-mobile-x3-5-weight-1-font-weight);
        line-height: var(--text-mobile-x3-5-weight-1-line-height);
        font-size: var(--text-mobile-x3-5-weight-1-font-size);
        letter-spacing: var(--text-mobile-x3-5-weight-1-letter-spacing);
      }
    }
  }
}

  @include lower-mobile {
    &-wrapper {
      &-trigger {
        &-container {
          &-button {
            min-width: 36px;
            max-width: 36px;
          }
        }
      }
    }
  }
}

.date-picker-months-label, .date-picker-years-label{
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-content: space-between;
  position: relative;
}
.date-picker-months-label{
  right: -10px;
}
.date-picker-years-label{
  right: -10px;
}
.date-picker-years-icon {
  position: relative;
  right: 20px;
  pointer-events: none;
  cursor: pointer;
  color: var(--gray-900);
}

.date-picker-months-icon {
  position: relative;
  right: 20px;
  pointer-events: none;
  cursor: pointer;
  color: var(--gray-900);
}
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  text-indent: 1px;
  text-overflow: '';
  position: relative;
}

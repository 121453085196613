.mobile-button {
  position: absolute;
  z-index: 20;
  bottom: 16px;
  left: 16px;
}

.zoom-button-block {
  position: initial;
}

.center-btn-maximize {
  border-radius: 12px;
  &:hover {
    box-shadow: 0 0 1px 0 rgb(44 44 54 / 4%), 0 0 2px 0 rgb(44 44 54 / 6%),
      0 4px 8px 0 rgb(44 44 54 / 4%);
  }

  @media (max-width: 1600px) {
    right: 88px;
  }
}

.minimap-btn-maximize {
  position: absolute;
  z-index: 401;
  right: 100px;
  bottom: 41px;

  @media all and (min-width: 768px) {
    top: 32px;
    right: 32px;
  }
}

.minimap-btn-minimize {
  position: absolute;
  z-index: 1000;
  top: 12px;
  right: 12px;
}

.tablet {
  top: 6px;
}

.center-btn-minimize {
  position: absolute;
  z-index: 401;
  right: 12px;
  bottom: 12px;
}

.map-tools {
  position: absolute;
  z-index: 401;
  top: 50%;
  left: 40px;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  gap: 20px;

  &-zoom > div {
    position: initial;
  }
}

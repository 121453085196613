@import "../../mixin.scss";

.container {
  margin-top: 16px;
  padding: 0 20px;

  .items-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    gap: 8px;
  
    width: 100%;

    padding: 0;
  
    &-wrapper {
      display: flex;
      flex-direction: column;
    }
  }

  @include lower-mobile {
    margin-top: 0px;

    &-title {
      font-size: var(--title-mobile-h3-weight-3-font-size);
    }
    
    .items-list {
      margin-top: 16px;
    }
  }
}

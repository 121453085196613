@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.tabSwitcher {
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow-x: auto;
  margin-right: -16px;
}

.tabSwitcherTab {
  display: flex;
  justify-content: center;
  padding: 8px 0;
  font-family: var(--button-desktop-lite-font-family);
  font-size: var(--button-desktop-lite-font-size);
  font-weight: var(--button-desktop-lite-font-weight);
  line-height: var(--button-desktop-lite-line-height);
  gap: 2px;
  cursor: pointer;
  transition: all 120ms ease-out;

  i {
    display: flex;
    align-items: center;
  }

  &.default {
    background: $interactive-light;
    color: var(--gray-600);
    border-radius: 8px;
    margin-right: 16px;
    min-height: 20px;

    &:hover {
      background: var(--interactive-light-hover);
      color: var(--gray-700);
    }

    &:active, &.active {
      background: var(--interactive-default);
      color: var(--gray-0);
    }
  }

  &.secondary {
    max-width: 56px;
    background: transparent;
    color: var(--gray-500);
    margin: 0 8px;

    &:hover {
      color: var(--interactive-hover);
      border-bottom: 2px solid var(--interactive-hover);
      padding-bottom: 2px;
    }

    &:active, &.active {
      color: var(--interactive-default);
      border-bottom: 2px solid var(--interactive-default);
      padding-bottom: 2px;
    }
  }

  &.large {
    min-width: 144px;
    width: 100%;
  }

  &.medium {
    min-width: 90.67px;
    width: 100%;
  }

  &.small {
    min-width: 64px;
    width: 100%;
  }

  &.extraSmall {
    min-width: 52px;
    width: 100%;
  }

  &.fill {
    width: 100%;
  }

  @include lower-mobile {
    font-family: var(--button-mobile-lite-font-family);
    font-size: var(--button-mobile-lite-font-size);
    font-weight: var(--button-mobile-lite-font-weight);
    line-height: var(--button-mobile-lite-line-height);
  }

}


@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";


.login {
    &-logo {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @include greater-mobile {
            margin-bottom: 64px;
        }

        &-text {
            color: var(--interactive-default);
            font-weight: var(--font-weights-semi-bold);
            font-size: var(--font-size-x4);
            font-family: var(--font-families-main);
            font-style: normal;
            line-height: var(--line-heights-x5);
            letter-spacing: var(--letter-spacing-m0-24);
            margin-left: 12px;

            @include greater-mobile {
                color: var(--surface-mid);
                font-size: var(--font-size-x8);
                line-height: var(--line-heights-x7);
                margin-left: 11.51px;
            }
        }
    }

    &-form {
        position: relative;
        z-index: 20;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        box-sizing: border-box;
        height: 100%;
        max-height: 600px;
        padding: 32px 16px 36px;

        max-width: 540px;
        height: auto;
        padding: 50px 56px 70px;
        background: var(--surface-front);
        border-radius: 24px;
        box-shadow: -8px 2px 28px rgba(2, 8, 32, 0.2);

        & > div {
            flex-direction: column;
        }

        @include lower-tablet {
            justify-content: center;
        }

        @include lower-mobile {
            width: 100%;
            padding: 0 30px 36px;
            gap: 0;
            box-shadow: none;
        }

        & > div {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 10px
        }
        &-button {
            display: flex;
            justify-content: center;
            align-items: center;
            max-height: 48px;
            min-width: fit-content;
            box-sizing: border-box;
            width: 100%;
            padding: 12px;
            color: var(--gray-0);
            font-weight: var(--button-desktop-default-font-weight);
            font-size: var(--button-desktop-default-font-size);
            font-family: var(--button-desktop-default-font-family);
            font-style: normal;
            line-height: var(--button-desktop-default-line-height);
            letter-spacing: var(--letter-spacing-m0-28);
            background: var(--interactive-default);
            text-decoration: var(--button-desktop-default-text-decoration);
            border: 1px solid transparent;
            cursor: pointer;
            transition: all 0.12s ease-out;
            gap: 8px;
            background: var(--interactive-default);
            border-radius: 8px;
            &:hover, &:active, &.active{
                background: var(--interactive-hover);
                color: var(--gray-0);
            }
            &:disabled{
                opacity: 0.25;
                &:hover{
                    background: var(--interactive-default);
                }
            }
        }


        &-logo {
            width: 100%;
            height: 72px;
            display: flex;
            object-fit: contain;

            @include greater-mobile {
            }
        }
        &-error {
            color: var(--validation-error-main);
        }
    }


}

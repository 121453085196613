@import "../../../mixin.scss";

.container {
    display: flex;
    position: relative;
    bottom: 66px;
    padding: 0 20px;

    gap: 40px;

    @include lower-mobile {
        padding: 0 16px;
        bottom: 52px;
    }
}

@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  min-width: fit-content;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  color: var(--gray-0);
  font-weight: var(--button-desktop-default-font-weight);
  font-size: var(--button-desktop-default-font-size);
  font-family: var(--button-desktop-default-font-family);
  font-style: normal;
  line-height: var(--button-desktop-default-line-height);
  letter-spacing: var(--button-desktop-default-letter-spacing);;
  background: var(--interactive-default);
  text-decoration: var(--button-desktop-default-text-decoration);
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.12s ease-out;
  gap: 8px;

  input[type=file] {
    display: none;
  }

  &.primary{
    background: var(--interactive-default);
    color: var(--gray-unchangeable-0);
    &:hover, &:active, &.active{
      background: var(--interactive-hover);
      color: var(--gray-0);
    }
    &:disabled{
      opacity: 0.25;
      &:hover{
        background: var(--interactive-default);
      }
    }
  }
  &.score {
    background: var(--interactive-default);
    color: var(--gray-0);
    &:hover, &:active, &.active{
      background: var(--score-satisfactory);
      color: var(--gray-0);
    }
    &:disabled{
      opacity: 0.25;
      &:hover{
        background: var(--interactive-default);
      }
    }
  }
  &.secondary{
    color: var(--gray-800);
    background: transparent;
    border: 1px solid #0D121C;
    &:hover, &:active, &.active{
      background: var(--interactive-light-hover);
      border: 1px solid transparent;
    }
    &:disabled{
      background: transparent;
      opacity: 0.25;
      &:hover{
        background: transparent;
        border: 1px solid #0D121C;
      }
    }
  }
  &.danger{
    background: var(--validation-error-bg);
    color: var(--validation-error-main);
    &:hover, &:active, &.active{
      color: var(--validation-error-content);
    }
    &:disabled{
      background: transparent;
      opacity: 0.25;
      &:hover{
        background: transparent;
        color: var(--validation-error-main);
      }
    }
  }
  &.dangerUncolored{
    background: transparent;
    color: var(--validation-error-main);
    &:hover, &:active, &.active{
      background: $validation-error-bg;
      color: var(--validation-error-content);
    }
    &:disabled{
      background: transparent;
      opacity: 0.25;
      &:hover{
        background: transparent;
        color: var(--validation-error-main);
      }
    }
  }
  &.elevation{
    color: var(--gray-800);
    background: var(--surface-front);
    box-shadow: 0px 2px 12px rgba(5, 18, 70, 0.06);
    &:hover, &:active, &.active{
      background: var(--interactive-light-hover);
      box-shadow: 0px 2px 12px rgba(2, 8, 32, 0.12);
    }
    &:disabled{
      opacity: 0.52;
      background: var(--surface-front);
      box-shadow: 0px 2px 12px rgba(5, 18, 70, 0.06);
    }
  }
  &.uncolored {
    color: var(--gray-800);
    background: transparent;
    &:hover, &:active, &.active{
      color: var(--interactive-hover);
    }
    &:disabled{
      background: transparent;
      color: var(--gray-800);
      opacity: 0.25;
    }
  }
  &.large {
    padding: 12px 16px;
    max-height: 48px;
    &.onlyIcon {
      max-width: 48px;
      min-width: 48px;
    }
  }
  &.medium {
    padding: 8px 12px;
    height: 40px;
    &.onlyIcon {
      max-width: 40px;
      min-width: 40px;
    }
  }
  &.small {
    max-height: 36px;
    padding: 8px;
    &.onlyIcon {
      max-width: 36px;
      min-width: 36px;
    }
  }

  &.contentStart{
    justify-content: start;
  }

  &.contentEnd {
    justify-content: end;
  }

  &.rounded{
    border-radius: 8px;
  }
  &.withBorder{
    border: 1px solid #0D121C;
  }
  &.onlyIcon {
    min-width: fit-content;
  }
  &.w-fill{
    max-width: 100%;
  }
  &.w-content{
    max-width: fit-content;
  }

  @include lower-tablet-landscape {
    font-weight: var(--button-tablet-default-font-weight);
    font-size: var(--button-tablet-default-font-size);
    font-family: var(--button-tablet-default-font-family);
    line-height: var(--button-tablet-default-line-height);
    letter-spacing: var(--button-tablet-default-letter-spacing);
    background: $interactive-default;
    text-decoration: var(--button-tablet-default-text-decoration);

    &.primary {
      &:hover, &:active {
        color: var(--gray-unchangeable-0);
        background: var(--interactive-default);
      }
      &.active {
        color: var(--gray-0);
        background: var(--interactive-hover);
      }
    }
    &.score {
      &:hover, &:active {
        color: var(--gray-0);
        background: var(--interactive-default);
      }
      &.active {
        color: var(--gray-0);
        background: var(--score-satisfactory);
      }
    }
    &.secondary {
      &:hover, &:active {
        background: transparent;
        border: 1px solid #0D121C;
      }
      &.active {
        background: var(--interactive-light-hover);
        border: 1px solid transparent;
      }
    }
    &.danger {
      &:hover, &:active {
        color: var(--validation-error-main);
      }
      &.active {
        color: var(--validation-error-content);
      }
    }
    &.dangerUncolored {
      &:hover, &:active {
        background: transparent;
        color: var(--validation-error-main);
      }
      &.active {
        background: $validation-error-bg;
        color: var(--validation-error-content);
      }
    }
    &.elevation {
      &:hover, &:active {
        background: var(--surface-front);
        box-shadow: 0px 2px 12px rgba(5, 18, 70, 0.06);
      }
      &.active {
        background: var(--interactive-light-hover);
        box-shadow: 0px 2px 12px rgba(2, 8, 32, 0.12);
      }
    }
    &.uncolored {
      &:hover, &:active {
        color: var(--gray-800);
      }
      &.active {
        color: var(--interactive-hover);
      }
    }

  }

  @include lower-mobile-landscape {
    font-weight: var(--button-mobile-default-font-weight);
    font-size: var(--button-mobile-default-font-size);
    font-family: var(--button-mobile-default-font-family);
    line-height: var(--button-mobile-default-line-height);
    letter-spacing: var(--button-mobile-default-letter-spacing);
    background: var(--interactive-default);
    text-decoration: var(--button-mobile-default-text-decoration);
  }
}



.button-text{
  white-space: nowrap;
}

@import 'oelp-tokens/css/variables';
@import '../../../mixin.scss';

.input-field {
    &-label {
        width: 100%;
        color: var(--gray-400);
        font-weight: normal;
        font-style: normal;
        line-height: 16px;

        &-text {
            display: inline-block;
            margin-bottom: 4px;
            padding-left: 8px;
            transition: all 0.12s ease-out;

            &.error {
                color: var(--validation-error-main);
            }
        }
        &:last-of-type {
            padding-bottom: 32px;
        }
        &:nth-of-type(n + 2) {
            padding-top: 12px;
        }
    }


    &-input {
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        color: var(--gray-800);
        font-family: var(--paragraph-desktop-x3-5-weight-1-font-family);
        font-weight: var(--paragraph-desktop-x3-5-weight-1-font-weight);
        line-height: var(--paragraph-desktop-x3-5-weight-1-line-height);
        font-size: var(--paragraph-desktop-x3-5-weight-1-font-size);
        letter-spacing: var(--paragraph-desktop-x3-5-weight-1-letter-spacing);
        line-height: 18px;
        border: 1.4px solid var(--gray-0);
        border-radius: 8px;
        outline: none;
        cursor: text;
        transition: all 0.12s ease-out;
        &-wrapper {
            position: relative;
        }

        &.error {
            border: 1.4px solid var(--validation-error-main);
        }


    }

    @include lower-tablet-landscape {
        &-label {
            &:last-of-type {
                padding-bottom: 16px;
            }
            &:nth-of-type(n + 2) {
                padding-top: 0;
            }
        }

        &-input {
            font-family: var(--paragraph-tablet-x3-5-weight-1-font-family);
            font-weight: var(--paragraph-tablet-x3-5-weight-1-font-weight);
            line-height: var(--paragraph-tablet-x3-5-weight-1-line-height);
            font-size: var(--paragraph-tablet-x3-5-weight-1-font-size);
            letter-spacing: var(--paragraph-tablet-x3-5-weight-1-letter-spacing);
        }
    }
}

.paragraph {
    @include lower-tablet-landscape {
        &-x4 {
            &-weight {
                &-3 {
                    @include typography-options(
                        var(--paragraph-tablet-x4-weight-3-font-family),
                        var(--paragraph-tablet-x4-weight-3-font-weight),
                        var(--paragraph-tablet-x4-weight-3-line-height),
                        var(--paragraph-tablet-x4-weight-3-font-size),
                        var(--paragraph-tablet-x4-weight-3-letter-spacing)
                        )
                }
                &-2 {
                    @include typography-options(
                        var(--paragraph-tablet-x4-weight-2-font-family),
                        var(--paragraph-tablet-x4-weight-2-font-weight),
                        var(--paragraph-tablet-x4-weight-2-line-height),
                        var(--paragraph-tablet-x4-weight-2-font-size),
                        var(--paragraph-tablet-x4-weight-2-letter-spacing)
                        )
                }
                &-1 {
                    @include typography-options(
                        var(--paragraph-tablet-x4-weight-1-font-family),
                        var(--paragraph-tablet-x4-weight-1-font-weight),
                        var(--paragraph-tablet-x4-weight-1-line-height),
                        var(--paragraph-tablet-x4-weight-1-font-size),
                        var(--paragraph-tablet-x4-weight-1-letter-spacing)
                        )
                }
            }
        }

        &-x3-5 {
            &-weight {
                &-3 {
                    @include typography-options(
                        var(--paragraph-tablet-x3-5-weight-3-font-family),
                        var(--paragraph-tablet-x3-5-weight-3-font-weight),
                        var(--paragraph-tablet-x3-5-weight-3-line-height),
                        var(--paragraph-tablet-x3-5-weight-3-font-size),
                        var(--paragraph-tablet-x3-5-weight-3-letter-spacing)
                        )
                }
                &-2 {
                    @include typography-options(
                        var(--paragraph-tablet-x3-5-weight-2-font-family),
                        var(--paragraph-tablet-x3-5-weight-2-font-weight),
                        var(--paragraph-tablet-x3-5-weight-2-line-height),
                        var(--paragraph-tablet-x3-5-weight-2-font-size),
                        var(--paragraph-tablet-x3-5-weight-2-letter-spacing)
                        )
                }
                &-1 {
                    @include typography-options(
                        var(--paragraph-tablet-x3-5-weight-1-font-family),
                        var(--paragraph-tablet-x3-5-weight-1-font-weight),
                        var(--paragraph-tablet-x3-5-weight-1-line-height),
                        var(--paragraph-tablet-x3-5-weight-1-font-size),
                        var(--paragraph-tablet-x3-5-weight-1-letter-spacing)
                        )
                }
            }
        }

        &-x3 {
            &-weight {
                &-1 {
                    @include typography-options(
                        var(--paragraph-tablet-x3-weight-1-font-family),
                        var(--paragraph-tablet-x3-weight-1-font-weight),
                        var(--paragraph-tablet-x3-weight-1-line-height),
                        var(--paragraph-tablet-x3-weight-1-font-size),
                        var(--paragraph-tablet-x3-weight-1-letter-spacing)
                        )
                }
                &-2 {
                    @include typography-options(
                        var(--paragraph-tablet-x3-weight-2-font-family),
                        var(--paragraph-tablet-x3-weight-2-font-weight),
                        var(--paragraph-tablet-x3-weight-2-line-height),
                        var(--paragraph-tablet-x3-weight-2-font-size),
                        var(--paragraph-tablet-x3-weight-2-letter-spacing)
                        )
                }
                &-3 {
                    @include typography-options(
                        var(--paragraph-tablet-x3-weight-3-font-family),
                        var(--paragraph-tablet-x3-weight-3-font-weight),
                        var(--paragraph-tablet-x3-weight-3-line-height),
                        var(--paragraph-tablet-x3-weight-3-font-size),
                        var(--paragraph-tablet-x3-weight-3-letter-spacing)
                        )
                }
            }
        }
        &-x2-75 {
            &-weight {
                &-2 {
                    @include typography-options(
                        var(--paragraph-tablet-x2-75-weight-2-font-family),
                        var(--paragraph-tablet-x2-75-weight-2-font-weight),
                        var(--paragraph-tablet-x2-75-weight-2-line-height),
                        var(--paragraph-tablet-x2-75-weight-2-font-size),
                        var(--paragraph-tablet-x2-75-weight-2-letter-spacing)
                        )
                }
                &-3 {
                    @include typography-options(
                        var(--paragraph-tablet-x2-75-weight-3-font-family),
                        var(--paragraph-tablet-x2-75-weight-3-font-weight),
                        var(--paragraph-tablet-x2-75-weight-3-line-height),
                        var(--paragraph-tablet-x2-75-weight-3-font-size),
                        var(--paragraph-tablet-x2-75-weight-3-letter-spacing)
                        )
                }
            }
        }
    }
}

@import 'oelp-tokens/css/variables';

.portal-container {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-color: $surface-back;
    z-index: 200;

    overflow: auto;
}
.portal-container::-webkit-scrollbar {
    display: none;
}
.spinner {
    display: flex;
    align-items: center;
    justify-content: center;
    box-sizing: content-box;
    width: 320px;
    height: 100vh;
    margin: auto;
    overflow: hidden;
    background: url('preloader.gif') center center no-repeat;
    background-size: 200%;
    
}

.spinner-content {
    position: relative;
    width: 100%;
    height: 100%;
    transform: translateZ(0) scale(0.8);
    transform-origin: 0 0;
    backface-visibility: hidden;
}

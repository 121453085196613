@import 'oelp-tokens/css/variables';

.container {
  display: inline-block;
  min-width: 107px;
  height: fit-content;

  overflow: hidden;

  width: calc(50% - 8px);

  background-color: rgba(0, 0, 0, 0);

  box-sizing: border-box;

  flex-grow: 1;

  transition: opacity 0.16s;

  &-tooltip {
    width: 96%;
  }

  &_disabled {
    opacity: 0.4;
    :hover {
      cursor: not-allowed;
    }
  }
}

.label {
  position: relative;
  display: flex;
  gap: 11px;
  align-items: center;

  min-width: 107px;
  height: fit-content;

  padding: 12px 12px 12px 16px;

  background-color: $surface-front;

  border-radius: 8px;

  cursor: pointer;

  font-family: var(--button-desktop-lite-font-family);
  font-weight: var(--button-desktop-lite-font-weight);
  line-height: var(--button-desktop-lite-line-height);
  font-size: var(--button-desktop-lite-font-size);

  text-overflow: ellipsis;

  overflow: hidden;

  // transition: all 0.12s ease-out, background-color 0.16s;
  transition: background-color 0.2s;
  z-index: 1;

  &-icon {
    width: 10px;
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
  }

  .left-icon {
    svg {
      width: 22px;
      height: 22px;
    }
  }

  & span {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  &:hover {
    background-color: $interactive-light-hover;
  }

  & p {
    font-family: var(--button-desktop-lite-font-size);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    align-items: center;
  }

  &-left-border {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    width: 8px;
  }

  &-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &-checkbox {
    & > label {
      pointer-events: none;
    }
  }

  &-checkmark {
    position: absolute;
    display: inline-block;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }

  // &.activeLabel {
  //   color: $gray-0;
  //   background-color: $interactive-hover;
  // }
}

:export {
  macro_region_pink: $macro-region-pink;
  macro_region_pink-alpha: $macro-region-pink-alpha;
  macro_region_orange: $macro-region-orange;
  macro_region_orange_alpha: $macro-region-orange-alpha;
  macro_region_dark_green: $macro-region-dark-green;
  macro_region_dark_green_alpha: $macro-region-dark-green-alpha;
  macro_region_blue: $macro-region-blue;
  macro_region_blue_alpha: $macro-region-blue-alpha;
  macro_region_outline_pink: $macro-region-outline-pink;
  macro_region_outline_orange: $macro-region-outline-orange;
  macro_region_outline_dark_green: $macro-region-outline-dark-green;
  macro_region_outline_blue: $macro-region-outline-blue;
}

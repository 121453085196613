@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.breadcrumbs{
  display: flex;
  gap: 8px;
  align-items: center;
  justify-content: center;
  padding: 6px 16px ;
  border-radius: 8px;
  background: var(--surface-front);
  box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06);
}

.w-fill{
  max-width: 100%;
}
.w-content{
  max-width: fit-content;
}

.breadcrumb-item-span, .breadcrumb-divider {
  color: var(--gray-800);
  white-space: nowrap;
  font-family: var(--text-desktop-x4-weight-1-font-family);
  font-weight: var(--text-desktop-x4-weight-1-font-weight);
  line-height: var(--text-desktop-x4-weight-1-line-height);
  font-size: var(--text-desktop-x4-weight-1-font-size);
  letter-spacing: var(--text-desktop-x4-weight-1-letter-spacing);
  text-decoration: var(--text-desktop-x4-weight-1-text-decoration);

  @include lower-tablet {
    font-family: var(--text-mobile-x4-weight-1-font-family);
    font-weight: var(--text-mobile-x4-weight-1-font-weight);
    line-height: var(--text-mobile-x4-weight-1-line-height);
    font-size: var(--text-mobile-x4-weight-1-font-size);
    letter-spacing: var(--text-mobile-x4-weight-1-letter-spacing);
    text-decoration: var(--text-mobile-x4-weight-1-text-decoration);
  }
}


.breadcrumb-item-link {
  font-family: var(--button-desktop-default-font-family);
  font-weight: var(--button-desktop-default-font-weight);
  line-height: var(--button-desktop-default-line-height);
  font-size: var(--button-desktop-default-font-size);
  letter-spacing: var(--button-desktop-default-letter-spacing);
  text-decoration: var(--button-desktop-default-text-decoration);
  white-space: nowrap;
  color: var(--gray-900);

  @include lower-tablet {
    font-family: var(--button-mobile-default-font-family);
    font-weight: var(--button-mobile-default-font-weight);
    line-height: var(--button-mobile-default-line-height);
    font-size: var(--button-mobile-default-font-size);
    letter-spacing: var(--button-mobile-default-letter-spacing);
    text-decoration: var(--button-mobile-default-text-decoration);
  }
}

.breadcrumb-divider{
  color: var(--gray-900);
}

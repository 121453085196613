@import 'oelp-tokens/css/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  width: 100%;
  padding: 12px 0;
}
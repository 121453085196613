@import "../../../../mixin.scss";

.controls {
  display: grid;
  grid-template-areas: 'play backward forward';
  gap: 3vw;
  grid-area: control;
  align-items: center;

  &-play {
    grid-area: play;
  }

  &-backward {
    grid-area: backward;
  }

  &-forward {
    grid-area: forward;
  }

  &-play, &-backward,  &-forward {
    justify-self: center;
  }

  &-center {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  @include lower-tablet {
    grid-template-areas: 'backward play forward';
    grid-template-columns: 7vh 7vh 7vh;
    justify-content: center;
  }
}


.player-hud-icon {
  font-size: 28px;
  color: var(--gray-50);
}


$icomoon-font-family: "OELP-Map-icon" !default;
$icomoon-font-path: "fonts" !default;

$icon-aerial-view: "\e900";
$icon-aeropanorama: "\e901";
$icon-aim: "\e902";
$icon-arrow-1-down: "\e903";
$icon-arrow-1-left: "\e904";
$icon-arrow-1-right: "\e905";
$icon-arrow-1-up: "\e906";
$icon-arrow-2-down: "\e907";
$icon-arrow-2-left: "\e908";
$icon-arrow-2-right: "\e909";
$icon-arrow-2-up: "\e90a";
$icon-arrow-3-left: "\e90b";
$icon-arrow-3-right: "\e90c";
$icon-backward: "\e90d";
$icon-calendar: "\e90e";
$icon-camera: "\e90f";
$icon-close-1: "\e910";
$icon-close-2: "\e911";
$icon-close-3: "\e912";
$icon-comand: "\e913";
$icon-cult: "\e914";
$icon-display: "\e915";
$icon-download: "\e916";
$icon-edit-1: "\e917";
$icon-edit-2: "\e918";
$icon-filter: "\e919";
$icon-forward: "\e91a";
$icon-help: "\e91b";
$icon-hide: "\e91c";
$icon-info: "\e91d";
$icon-link: "\e91e";
$icon-list: "\e91f";
$icon-lock: "\e920";
$icon-log-out: "\e921";
$icon-map: "\e922";
$icon-map-pin: "\e923";
$icon-message: "\e924";
$icon-minus: "\e925";
$icon-more-1: "\e926";
$icon-more-2: "\e927";
$icon-notification: "\e928";
$icon-object: "\e929";
$icon-objects: "\e92a";
$icon-open-1: "\e92b";
$icon-open-2: "\e92c";
$icon-panorama: "\e92d";
$icon-password: "\e92e";
$icon-pause: "\e92f";
$icon-phone: "\e930";
$icon-play: "\e931";
$icon-plus: "\e932";
$icon-privacy-policy: "\e933";
$icon-read: "\e934";
$icon-save: "\e935";
$icon-search: "\e936";
$icon-share: "\e937";
$icon-show: "\e938";
$icon-star: "\e939";
$icon-table: "\e93a";
$icon-trashcan: "\e93b";
$icon-turn-left: "\e93c";
$icon-turn-right: "\e93d";
$icon-under-construction: "\e93e";
$icon-unlock: "\e93f";
$icon-user: "\e940";
$icon-video: "\e941";
$icon-volume-down: "\e942";
$icon-volume-off: "\e943";
$icon-volume-up: "\e944";


.container {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;

    background-color: rgba(0, 0, 0, .4);

    &-button {
       & > i {
        font-size: 10vh!important;
        color: var(--gray-0);
       } 
    }
}

@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.profile-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 48px;
  min-height: 48px;
  max-width: 60px;
  max-height: 60px;
  width: 100%;
  height: 100%;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.12s ease-out;
  background: $gray-unchangeable-0;
  &:hover{
    background: $surface-blackout;
  }
  &:hover > svg.icon {
    display: block;
    filter: brightness(1);
  }
  img  {
    display: block;
    width: auto;
    height: 100%;
    object-fit: contain;
    object-position: center;
    &:hover{
      filter: brightness(0.8);
    }
    &:hover + svg.icon {
      display: block;
      filter: brightness(1);
    }
  }
  .icon {
    display: none;
    position: absolute;
    pointer-events: none;
    transition: all 0.12s ease-out;
  }

  @include lower-tablet-landscape {
      min-width: 40px;
      min-height: 40px;
  }

  @include lower-mobile-landscape {
    min-width: 36px;
    min-height: 36px;
  }
}

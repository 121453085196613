@import 'oelp-tokens/css/variables';

.profile-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    // font-family: $FontFamilyDefault;
    color: $gray-800;

    &-button-change {
        width: 295px;
        height: 44px;
        border: none;
        outline: none;
        color: $surface-front;
        background-color: $interactive-default;
        border-radius: 8px;
    }

    &-section {
        display: flex;
        align-items:flex-start;
        gap: 20px;

        @media all and (max-width: 767px) {
            flex-direction: column;
        }
        &-error {
            width: 288px;
            color: #FF0000;
        }
}

    &-button {
        width: 295px;
        height: 44px;
        border: none;
        outline: none;
    }


    &-button-save {
        border-radius: 8px;
        color: $gray-400;
        width: 295px;
        height: 44px;
        border: none;
        outline: none;

        @media all and (max-width: 767px) {
            color: $surface-front;
            background-color: $interactive-default;
            font-weight: normal;
            font-size: $font-size-x4;
            font-style: normal;
            line-height: 18px;
            letter-spacing: -0.28px;
        }


    }

    &-button-save.no-errors {
        color: $surface-front;
        background-color: $interactive-default;
    }

    .change-password-button {
        display: flex;
        justify-content: center;
        align-items: center;
        justify-self: flex-start;
        margin-top: 21px;
        padding: 15px 16px;
        width: 288px;
        height: 48px;
        border-radius: 8px;
    }
}

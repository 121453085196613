@import "../../../../mixin.scss";

.input {
  $height: 4px;
  $hovered-height: 8px;

  &-container {
    user-select: none;

    position: relative;

    width: 100%;
    max-width: 100%;
    height: 32px;

    transition: height 0.2s;

    & > .input_background,
    & > .input_loaded,
    & > .input_hovered,
    & > .input_progress {
      height: $height;
    }

    &:hover {
      & > .input_background,
      & > .input_loaded,
      & > .input_hovered,
      & > .input_progress {
        height: $hovered-height;

        border-radius: 4px;
      }

      & > .input-tooltip:not(.input-tooltip_mobile) {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  &_background {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    width: 100%;
    height: 100%;

    opacity: 0.32;
    background: var(--gray-500);
    border-radius: 2px;

    transition: border-radius 0.2s, height 0.2s;
  }

  &_loaded {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    height: 100%;

    background: var(--gray-600);
    border-radius: 2px;

    transition: border-radius 0.2s, height 0.2s;
  }

  &_progress {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    height: 100%;

    background: var(--interactive-default);
    border-radius: 2px;

    transition: border-radius 0.2s, width 0.18s linear, height 0.2s;

    &_without-effects {
      transition: none;
    }
  }

  &_hovered {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);

    width: 0;
    height: 100%;

    opacity: 0.4;
    background: var(--gray-50);
    border-radius: 2px;

    transition: border-radius 0.2s, height 0.2s;
  }

  &-dot {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(-50%, -50%);

    width: 16px;
    height: 16px;

    visibility: visible;
    background: var(--interactive-default);
    border-radius: 50%;

    &_hidden {
      visibility: hidden;
    }
  }

  &-tooltip {
    position: absolute;
    z-index: 1000;
    top: -4px;
    left: 6px;
    transform: translate(calc(-50%), -100%);

    min-width: 37px;
    padding: 4px 8px;

    font-size: 12px;
    font-weight: 400;
    line-height: 16px;
    color: var(--gray-unchangeable-1000);
    letter-spacing: -0.2px;

    visibility: hidden;
    opacity: 0;
    background-color: var(--gray-50);
    filter: var(--shadow-elevation-popup);
    border-radius: 8px;

    transition: opacity 0.2s;

    &::before {
      content: '';

      position: absolute;
      z-index: -1;
      top: 90%;
      left: 50%;
      transform: translate(-50%, -50%) rotate(45deg);

      width: 10px;
      height: 10px;

      background-color: var(--gray-50);
    }

    &_visable {
      visibility: visible;
      opacity: 1;
    }
  }

  @include lower-mobile {
    &-container {
      margin-bottom: 4vh;
    }
  }
}

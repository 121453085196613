.snackbar-container {
  display: flex;
  gap: 8px;
  align-items: center;

  width: fit-content;
  min-width: 91px;
  min-height: 52px;
  padding: 8px 16px;

  border-radius: 12px;
  box-shadow: var(--shadow-elevation-popup);

  transition: all 120ms ease-out;

  &.snackbar-mobile {
    justify-content: space-around;

    width: 100vw;
    max-width: 288px;

    &.snackbar-loading {
      & .snackbar-content {
        justify-content: flex-start;
      }
    }
  }

  &.snackbar-default {
    background: var(--surface-dark-blackout);
    &.snackbar_with-action {
      padding-right: 8px;
      padding-left: 12px;
    }
  }

  &.snackbar-loading {
    padding-right: 16px;
    padding-left: 8px;

    background: var(--surface-dark-blackout);
  }

  &.snackbar-error {
    padding-right: 8px;
    padding-left: 16px;

    background: var(--validation-error-main);
  }

  > .snackbar-content-container {
    pointer-events: initial;

    display: flex;
    gap: 8px;
    align-items: center;
    justify-content: space-between;

    width: 100%;

    transition: width 160ms;

    & p,
    & span {
      max-width: 358px;

      color: var(--gray-50) !important;
      line-break: auto;
    }

    i {
      font-size: 24px;
      color: var(--gray-50);
    }

    button:not([type='button']) {
      padding: 6px;
      > div {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    > .snackbar-content {
      display: flex;
      gap: 8px;
      align-items: center;
      justify-content: space-around;

      width: 100%;
      .snackbar-action-container {
        display: flex;
        gap: 8px;
        align-items: center;
      }
      .snackbar-spinner {
        width: 24px;
        height: 24px;

        & svg {
          width: 100%;
          height: 100%;

          & circle {
            stroke: var(--gray-500);
            stroke-width: 3;
          }
        }
      }
    }
  }
}

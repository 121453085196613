@import 'oelp-tokens/css/variables';

.header-list-view-background{
  position: absolute;
  display: flex;
  width: 100%;
  height: 80px;
  background-color: $surface-front;
}

.header-container {
  display: flex;
  align-items: flex-start;
  padding: 20px 20px 12px;
  justify-content: space-between;
  position: absolute;
  width: 100%;
  flex-wrap: wrap;
  gap: 20px;
  min-height: 80px;
  transition: width .12s ease-out;

  z-index: 500;

  &-sidebar-active {
    width: calc(100% - 400px);
  }

  &.header-list-view-bg {
    background-color: $surface-front;
  }

  &.opened {
    width: 90%
  }
  @media all and (max-width: 1198px) {
    padding: 30px;
  }
  @media all and (max-width: 990px) {
    padding: 24px;
  }
  @media all and (max-width: 785px) {
    padding: 16px;
  }
  @media all and (max-width: 590px) {
    padding: 12px;
  }
}

@import 'oelp-tokens/css/variables';

.object-info {
  display: flex;
  flex-direction: column;

  &-content {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-header {
      color: var(--gray-400);
      font-size: var(--subheader-desktop-weight-1-font-size);
      font-weight: var(--subheader-desktop-weight-1-font-weight);
      line-height: var(--subheader-desktop-weight-1-line-height);
    }

    &-row {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: start;
      gap: 4px;
    }

    &-title {
      font-family: var(--text-desktop-x3-5-weight-2-font-family);
      font-size: var(--text-desktop-x3-5-weight-2-font-size);
      font-weight: var(--text-desktop-x3-5-weight-2-font-weight);
      line-height: var(--text-desktop-x3-5-weight-2-line-height);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    &-value {
      color: var(--gray-800);
      font-family: var(--text-desktop-x3-5-weight-1-font-family);
      font-size: var(--text-desktop-x3-5-weight-1-font-size);
      font-weight: var(--text-desktop-x3-5-weight-1-font-weight);
      line-height: var(--text-desktop-x3-5-weight-1-line-height);

      &__a {
        color: #689aff;

        cursor: pointer;

        transition: all 0.3s ease-out;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

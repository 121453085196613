@import "../../mixin.scss";

.video-player-container {
  isolation: isolate;
  position: relative;

  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;

  max-width: inherit;
  max-height: inherit;

  background-color: var(--surface-dark-back);
  border-radius: 16px;

  &_fullscreen {
    width: 100%;
    height: 100%;

    border-radius: 0;

    & video {
      border-radius: 0;
    }
  }

  &-cover {
    position: absolute;
    z-index: 1000;
    top: 0;
    left: 0;

    width: 100%;
    height: 100%;

    visibility: visible;
    opacity: 1;
    background: var(--surface-blackout);

    transition: opacity 0.2s;

    &-icon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      font-size: 60px;
      color: var(--gray-50);
    }

    &_hidden {
      opacity: 0;
    }
  }

  & > [data-type='hud'] {
    visibility: visible;
    opacity: 1;
  }

  &_playing.video-player-container_show-hud-auto {
    & > [data-type='hud'] {
      opacity: 0;
    }
    &:not(.video-player-container_show-hud-hidden_playing):hover
      > [data-type='hud'] {
      visibility: visible;
      opacity: 1;
    }
  }

  &_show-hud-hidden {
    & > [data-type='hud'] {
      opacity: 0;
    }
  }

  @include greater-mobile {
    min-width: 600px;
    & video {
      min-width: 600px;
    }
  }
}

.video-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;
}

.title {
    @include lower-tablet-landscape {
        &-h1 {
            &-weight {
                &-3 {
                    @include typography-options(
                        var(--title-tablet-h1-weight-3-font-family),
                        var(--title-tablet-h1-weight-3-font-weight),
                        var(--title-tablet-h1-weight-3-line-height),
                        var(--title-tablet-h1-weight-3-font-size),
                        var(--title-tablet-h1-weight-3-letter-spacing)
                    )
                }

                &-2 {
                    @include typography-options(
                        var(--title-tablet-h1-weight-2-font-family),
                        var(--title-tablet-h1-weight-2-font-weight),
                        var(--title-tablet-h1-weight-2-line-height),
                        var(--title-tablet-h1-weight-2-font-size),
                        var(--title-tablet-h1-weight-2-letter-spacing)
                    )
                }
                
                &-1 {
                    @include typography-options(
                        var(--title-tablet-h1-weight-1-font-family),
                        var(--title-tablet-h1-weight-1-font-weight),
                        var(--title-tablet-h1-weight-1-line-height),
                        var(--title-tablet-h1-weight-1-font-size),
                        var(--title-tablet-h1-weight-1-letter-spacing)
                    )
                }
            }
        }

        &-h2 {
            &-weight {
                &-3 {
                    @include typography-options(
                        var(--title-tablet-h2-weight-3-font-family),
                        var(--title-tablet-h2-weight-3-font-weight),
                        var(--title-tablet-h2-weight-3-line-height),
                        var(--title-tablet-h2-weight-3-font-size),
                        var(--title-tablet-h2-weight-3-letter-spacing)
                    )
                }

                &-2 {
                    @include typography-options(
                        var(--title-tablet-h2-weight-2-font-family),
                        var(--title-tablet-h2-weight-2-font-weight),
                        var(--title-tablet-h2-weight-2-line-height),
                        var(--title-tablet-h2-weight-2-font-size),
                        var(--title-tablet-h2-weight-2-letter-spacing)
                    )
                }
                
                &-1 {
                    @include typography-options(
                        var(--title-tablet-h2-weight-1-font-family),
                        var(--title-tablet-h2-weight-1-font-weight),
                        var(--title-tablet-h2-weight-1-line-height),
                        var(--title-tablet-h2-weight-1-font-size),
                        var(--title-tablet-h2-weight-1-letter-spacing)
                    )
                }
            }
        }

        &-h3 {
            &-weight {
                &-3 {
                    @include typography-options(
                        var(--title-tablet-h3-weight-3-font-family),
                        var(--title-tablet-h3-weight-3-font-weight),
                        var(--title-tablet-h3-weight-3-line-height),
                        var(--title-tablet-h3-weight-3-font-size),
                        var(--title-tablet-h3-weight-3-letter-spacing)
                    )
                }

                &-2 {
                    @include typography-options(
                        var(--title-tablet-h3-weight-2-font-family),
                        var(--title-tablet-h3-weight-2-font-weight),
                        var(--title-tablet-h3-weight-2-line-height),
                        var(--title-tablet-h3-weight-2-font-size),
                        var(--title-tablet-h3-weight-2-letter-spacing)
                    )
                }
                
                &-1 {
                    @include typography-options(
                        var(--title-tablet-h3-weight-1-font-family),
                        var(--title-tablet-h3-weight-1-font-weight),
                        var(--title-tablet-h3-weight-1-line-height),
                        var(--title-tablet-h3-weight-1-font-size),
                        var(--title-tablet-h3-weight-1-letter-spacing)
                    )
                }
            }
        }

        &-h4 {
            &-weight {
                &-3 {
                    @include typography-options(
                        var(--title-tablet-h4-weight-3-font-family),
                        var(--title-tablet-h4-weight-3-font-weight),
                        var(--title-tablet-h4-weight-3-line-height),
                        var(--title-tablet-h4-weight-3-font-size),
                        var(--title-tablet-h4-weight-3-letter-spacing)
                    )
                }

                &-2 {
                    @include typography-options(
                        var(--title-tablet-h4-weight-2-font-family),
                        var(--title-tablet-h4-weight-2-font-weight),
                        var(--title-tablet-h4-weight-2-line-height),
                        var(--title-tablet-h4-weight-2-font-size),
                        var(--title-tablet-h4-weight-2-letter-spacing)
                    )
                }
                
                &-1 {
                    @include typography-options(
                        var(--title-tablet-h4-weight-1-font-family),
                        var(--title-tablet-h4-weight-1-font-weight),
                        var(--title-tablet-h4-weight-1-line-height),
                        var(--title-tablet-h4-weight-1-font-size),
                        var(--title-tablet-h4-weight-1-letter-spacing)
                    )
                }
            }
        }
    }
}

.subheader {
    @include lower-tablet {
        &-weight {
            &-3 {
                @include typography-options(
                    var(--subheader-tablet-weight-3-font-family),
                    var(--subheader-tablet-weight-3-font-weight),
                    var(--subheader-tablet-weight-3-line-height),
                    var(--subheader-tablet-weight-3-font-size),
                    var(--subheader-tablet-weight-3-letter-spacing)
                )
            }

            &-2 {
                @include typography-options(
                    var(--subheader-tablet-weight-2-font-family),
                    var(--subheader-tablet-weight-2-font-weight),
                    var(--subheader-tablet-weight-2-line-height),
                    var(--subheader-tablet-weight-2-font-size),
                    var(--subheader-tablet-weight-2-letter-spacing)
                )
            }

            &-1 {
                @include typography-options(
                    var(--subheader-tablet-weight-1-font-family),
                    var(--subheader-tablet-weight-1-font-weight),
                    var(--subheader-tablet-weight-1-line-height),
                    var(--subheader-tablet-weight-1-font-size),
                    var(--subheader-tablet-weight-1-letter-spacing)
                )
            }
        } 
    }
}

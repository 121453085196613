.player-hud-container {
  position: absolute;
  z-index: 10000;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;

  width: 100%;
  height: 104px;
  padding: 0 24px 28px;

  visibility: hidden;
  opacity: 0;
  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 80%) 80.73%
  );

  transition: opacity 0.5s;

  &-line {
    height: 32px;
  }

  &-controls {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &-player,
    &-left {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }

    &-button:hover .player-hud-icon {
      font-weight: 700;
      color: var(--interactive-default);
    }

    &-button {
      &-share-container {
        bottom: 390px;
        left: 390px;

        width: 375px;
      }
    }
  }

  &_fullscreen {
    height: 128px;
    padding: 14px 40px 0;

    & .player-hud-container-controls {
      &-button {
        &-share-container {
          bottom: 104px;
        }
      }
    }
  }

  &_pause {
    & .player-hud-container-controls {
      &_visable {
        opacity: 1;

        transition: opacity 260ms ease-out;
      }

      &_hidden {
        opacity: 0;

        transition: opacity 260ms ease-out;
      }
    }
  }
}

.player-hud-icon {
  font-size: 28px;
  color: var(--gray-50);
}

.player-hud-time {
  margin-left: 32px;
  min-width: fit-content;
  font-family: var(--caption-x3-25-weight-2-font-family);
  font-size: var(--caption-x3-25-weight-2-font-size);
  font-weight: var(--caption-x3-25-weight-2-font-weight);
  line-height: var(--caption-x3-25-weight-2-line-height);
  color: var(--gray-50);
  letter-spacing: var(--caption-x3-25-weight-2-letter-spacing);
}

@import 'oelp-tokens/css/variables';

.loader {
  background-color: white;
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  & svg {
    width: 32px;
    height: 32px;
  }
}

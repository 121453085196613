@import 'oelp-tokens/css/variables';

.object-info {
  padding: 0 16px;
  background: var(--surface-front);
  border-radius: 8px;
  height: 100%;
  height: 85%;
  display: flex!important;
  flex-direction: column;
  justify-content: space-between;

  &-heading {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    &-text {
      font-family: var(--text-desktop-x3-5-weight-2-font-family);
      font-size: var(--text-desktop-x3-5-weight-2-font-size);
      font-weight: var(--text-desktop-x3-5-weight-2-font-weight);
      line-height: var(--text-desktop-x3-5-weight-2-line-height);
    }
  }

  &-content {
    display: flex;
    flex-direction: column;
    position: relative;
    margin-bottom: 25px;

    div {
      cursor: default;
    }

    &-title {
      font-family: var(--text-desktop-x3-5-weight-2-font-family);
      font-size: var(--text-desktop-x3-5-weight-2-font-size);
      font-weight: var(--text-desktop-x3-5-weight-2-font-weight);
      line-height: var(--text-desktop-x3-5-weight-2-line-height);
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
      margin-top: 12px;
      cursor: pointer;
    }

    &-value {
      font-family: var(--text-desktop-x3-5-weight-1-font-family);
      font-size: var(--text-desktop-x3-5-weight-1-font-size);
      font-weight: var(--text-desktop-x3-5-weight-1-font-weight);
      line-height: var(--text-desktop-x3-5-weight-1-line-height);
    }
  }

  &-buttons-group {
    max-width: 100% !important;

    @media screen and (max-width: 1345px) {
      flex-wrap: wrap;

      button {
        margin: 0;
      }

      p {
        width: 100%;

        & > button {
          max-width: 100%!important;
        }
      }
    }

    &-button-favorite {
      min-width: 48px;
      background-color: pink;
    }
    

    &-button-display, &-button-favorite {
      min-width: 150px;
      width: 100%;;
      white-space: nowrap;
      gap: 4px;
    }

    &-share-container {
      position: relative;

      display: flex;
      justify-content: center;

      margin: 0;

      &-button {
        width: 100%;;
        padding: 8px!important;
      }

      &-tooltip {
        position: absolute;
        bottom: -35px;
        right: 0;
        width: 160px;

        padding: 6px 8px;
        color: var(--gray-50);
        font-family: var(--text-desktop-x3-5-weight-1-font-family);
        font-size: var(--text-desktop-x3-5-weight-1-font-size);
        font-weight: var(--text-desktop-x3-5-weight-1-font-weight);
        line-height: var(--text-desktop-x3-5-weight-1-line-height);
        background: var(--surface-dark-blackout);
        border-radius: 8px;

        text-align: center;

        opacity: 0;
        transition: all .12s ease-out;
      }

      &-tooltip-active {
        animation: clipboard-tooltip 3s ease;
      }     
      @keyframes clipboard-tooltip {
        10% { opacity: 1; }   
        60% { opacity: 1; }   
        95% { opacity: 0; }
      } 
    }

    &-button-monitoring {
      margin-bottom: 8px!important;

      &-disabled {
        cursor: default;
      }
    }
  }

  &-button {
    > i {
      font-size: 20px!important;
    } 
  }
}

@import 'oelp-tokens/css/variables';
@import '../../mixin.scss';

.card {
  &-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    color: var(--gray-900);
    &-info {
      height: 100%;
    }
  }
  &-button-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    gap: 8px;
  }

  &-buttons-container {
    display: flex;
    justify-content: center;
    gap: 8px;
    width: 100%;
    padding: 0 16px;
  }

  justify-content: space-between;
  &-header {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    position: relative;
    max-height: 180px;
    padding: 0;
    &-title,
    &-subtitle {
      color: var(--gray-900);

      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;

      word-break: break-word;
    }
  }

  &-footer {
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding: 16px 16px 0;
  }

  &-empty-photo-list {
    width: 100%;
    height: 204px;

    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
  }

  &-info {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 20px;
    padding: 12px 16px;
    overflow: visible;
    width: 100%;
    height: 100%;
  }

  &-heading {
    display: flex;
    flex-direction: column;
    align-items: start;
    padding: 0 16px;

    &-text {
      display: flex;
      justify-content: space-between;
      width: 100%;

      &-title {
        max-width: 340px;
        min-width: 270px;
        max-height: 47px;
        text-overflow: ellipsis;
        margin-bottom: 6px;
      }
      h4 {
        display: inline-block;
        max-width: 340px;
        min-width: 270px;
        text-overflow: ellipsis;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        padding-right: 10px;
        margin-bottom: 6px;
      }
    }

    &-full-title {
      display: flex;
      margin: 16px 0 -13px 0;
      min-height: 24px;
      align-items: center;
      color: var(--interactive-default);

      &-name {
        display: flex;
        align-items: center;
        cursor: pointer;

        &:hover .object-header-text {
          display: flex;
        }
      }
      i {
        cursor: pointer;
      }
    }
  }

  // TABLET

  &-tablet-image {
    width: 100%;
    min-height: 180px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 40%;
    border-radius: 8px 8px 0 0;
  }

  &-tablet-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    padding: 20px 16px;

    &-buttons {
      display: flex;
      gap: 8px;
    }
  }

  &-tablet-info {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &-title {
      margin: 0;
    }

    & * {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  // MOBILE

  &-mobile-image {
    width: 100%;
    min-height: 128px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position-y: 40%;
    border-radius: 8px 8px 0 0;
  }

  &-mobile-info {
    display: flex;
    width: 100%;
    height: 100%;
    flex-direction: column;
    justify-content: flex-start;
    padding: 0.8rem;

    &-title {
      margin: 0;
    }

    & * {
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
  }

  @include lower-tablet-landscape {
    min-width: 288px;
    width: 100%;
    max-width: 484px;
    min-height: auto;
    flex-grow: 1;
  }

  @include lower-tablet {
    min-width: 288px;
    width: 350px;
    flex-grow: 1;
  }
}

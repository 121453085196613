@import 'oelp-tokens/css/variables';
.gallery-panel {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: end;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 3000;
    width: 100%;
    height: 100%;
    background: $surface-back;

  img {
    width: auto;
    max-width: 100%;
    border-radius: 16px;
  }


&_btn-box {
    position: absolute;
    display: flex;
    // padding: 0 40px 25px 40px;
    top: 10px;
    right: 10px;
    z-index: 1;

&_add-btn {
     text-align: center;
     cursor: pointer;
     user-select: none;
     color: $surface-front;
     background-color: $interactive-default;
     font-size: 16px;
     letter-spacing: -0.28px;
     line-height: 18px;
     padding: 8px;
     height: 34px;
     border: none;
     border-radius: 8px;
     width: 144px;
     margin-right: 16px;
img {
    margin-right: 6px;
}
input[type="file"] {
    display: none;
}
}
&_delete-btn {
     color: $surface-front;
     background-color: $validation-error-main;
     font-size: 16px;
     letter-spacing: -0.28px;
     line-height: 18px;
     padding: 8px;
     user-select: none;
     height: 34px;
     border: none;
     border-radius: 8px;
     width: 133px;
img {
    margin-right: 6px;
}
}
.button-close {
    display: inline-block;
    vertical-align: middle;
  position: relative;
  right: -20px;
}
}
}

.gallery-panel-popup-message {
 //display: flex;
 //flex-direction: column;
 //  min-height: 90px;
 //   height: auto;
     width: 290px;
     border: none;
     border-radius: 10px;
     font-size: 16px;
     letter-spacing: -0.28px;
     line-height: 18px;
     font-weight: 600;

&-title {
     font-size: 16px;
     font-weight: 600;
 //    margin-left: 10px;
 //    margin-top: 5px;
 }

&-body {
     font-size: 14px;
     letter-spacing: -0.28px;
     line-height: 18px;
     font-weight: 500;
 //    margin-left: 10px;
 }
}
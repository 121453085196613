.button {
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  width: max-content;

  font-size: var(--button-default-font-size);
  font-weight: var(--button-default-font-weight);
  line-height: var(--button-default-line-height);
  letter-spacing: var(--button-default-letter-spacing);

  border: none;
  outline: none;

  transition: var(--transition-default);

  &-children {
    padding: 4px 0;

    white-space: nowrap;

    &_small {
      padding: 2px 0;
    }

    &.text-wrap {
      word-wrap: break-word;
      white-space: pre-wrap;
    }

    &-text_ellipsis {
      overflow: hidden;

      text-overflow: ellipsis;
    }

    &-text_inherit {
      text-overflow: inherit;
    }
  }

  &-content-container {
    display: flex;
    flex-direction: row;
    gap: 6px;
    align-items: center;
    justify-content: center;

    width: 100%;

    &_hidden {
      opacity: 0;
    }

    &_left {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      justify-content: left;

      width: 100%;
    }
    &_right {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
      justify-content: right;

      width: 100%;
    }
  }

  &-spinner-container {
    position: absolute;
  }

  &_large {
    gap: 6px;

    padding: 12px 20px;

    border-radius: 12px;
  }

  &_medium {
    gap: 4px;

    padding: 8px 12px;

    border-radius: 10px;
    i {
      font-size: 28px;
    }
  }

  &_small {
    gap: 4px;

    padding: 6px 8px;

    font-style: normal;

    border-radius: 8px;
    i {
      font-size: 24px;
    }
  }

  &_primary {
    color: var(--gray-unchangeable-50);

    background-color: var(--interactive-default);
    &:active {
      color: var(--gray-unchangeable-50);

      background-color: var(--interactive-default);
    }
    &:disabled {
      cursor: not-allowed;

      opacity: 0.12;
    }
    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: var(--interactive-hover);
        &:active {
          background-color: var(--interactive-default);
        }
      }
    }
  }

  &_secondary {
    color: var(--interactive-default);

    background-color: transparent;
    &:active {
      color: var(--gray-unchangeable-50);

      background-color: var(--interactive-default);
    }
    &:disabled {
      cursor: not-allowed;

      opacity: 0.16;
    }

    &_active {
      color: var(--gray-unchangeable-50);

      background-color: var(--interactive-default);

      @media (hover: hover) {
        &:hover:not(:disabled) {
          background-color: var(--interactive-hover);
          &:active {
            color: var(--interactive-default);

            background-color: transparent;
          }
        }
      }
    }

    @media (hover: hover) {
      &:hover:not(:disabled) {
        background-color: var(--interactive-light-hover);
        &:active {
          color: var(--gray-unchangeable-50);

          background-color: var(--interactive-default);
        }
      }
    }
  }

  &_elevation {
    color: var(--gray-800);

    background-color: var(--surface-front);
    box-shadow: var(--shadow-elevation-default);

    &:disabled {
      cursor: not-allowed;

      opacity: 0.16;
    }
    &_active {
      color: var(--gray-unchangeable-50);

      background-color: var(--interactive-default);
      @media (hover: hover) {
        &:hover:not(:disabled) {
          color: var(--gray-unchangeable-50);

          background-color: var(--interactive-hover);
          &:active {
            color: var(--gray-800);

            background-color: var(--surface-front);
          }
        }
      }
    }

    @media (hover: hover) {
      &:hover:not(:disabled) {
        color: var(--gray-1000);

        background-color: var(--interactive-light-hover);
        box-shadow: var(--shadow-elevation-default);
        &:active {
          color: var(--gray-unchangeable-50);

          background-color: var(--interactive-default);
        }
      }
    }
  }

  &_uncolored {
    color: var(--gray-800);

    background-color: transparent;

    &:disabled {
      cursor: not-allowed;

      opacity: 0.16;
    }

    &_active {
      color: var(--interactive-default);
      @media (hover: hover) {
        &:hover:not(:disabled) {
          color: var(--interactive-hover);
          &:active {
            color: var(--gray-800);
          }
        }
      }
    }

    @media (hover: hover) {
      &:hover:not(:disabled) {
        color: var(--interactive-hover);
        & i {
          color: var(--interactive-hover);
        }
        &:active {
          color: var(--interactive-default);
          & i {
            color: var(--interactive-default);
          }
        }
      }
    }
  }

  &_danger {
    color: var(--validation-error-main);

    background-color: var(--validation-error-bg);

    &:disabled {
      cursor: not-allowed;

      opacity: 0.24;
    }
    @media (hover: hover) {
      &:hover:not(:disabled) {
        color: var(--validation-error-content);

        background-color: var(--validation-error-main);
        &:active {
          color: var(--validation-error-bg);
        }
      }
    }
  }

  &_dangerUncolored {
    color: var(--validation-error-main);

    background-color: transparent;
    &:disabled {
      cursor: not-allowed;

      opacity: 0.24;
    }
    @media (hover: hover) {
      &:hover:not(:disabled) {
        color: var(--validation-error-content);
        &:active {
          color: var(--validation-error-main);
        }
      }
    }
  }

  &_fullwidth {
    width: 100%;
  }

  &_lite {
    font-size: var(--button-lite-font-weight);
    font-weight: var(--button-lite-font-weight);
    font-style: normal;
    line-height: var(--button-lite-line-height);
  }

  &_squared {
    &.button_large {
      width: 52px;
      height: 52px;
      padding: 12px;
    }
    &.button_medium {
      width: 44px;
      height: 44px;
      padding: 8px;
    }
    &.button_small {
      width: 36px;
      height: 36px;
      padding: 6px;
    }
  }
  &-link {
    &:hover {
      color: inherit;
    }
  }
}

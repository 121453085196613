.checkbox {
  &__container {
    display: flex;
    align-items: center;

    margin: 0;
    padding: 0;
    input[type='checkbox'] {
      cursor: pointer;

      width: 20px;
      min-width: 20px;
      height: 20px;
      min-height: 20px;
      margin: 4px;

      border: 1px solid var(--gray-100);
      border-radius: 4px;
      outline: none;

      appearance: none;
      &.checked {
        position: relative;

        background-color: var(--interactive-default);
        border: none;
        &::before {
          content: url('./Tick.svg');

          position: absolute;
          top: calc(50% - 1px);
          left: 50%;
          transform: translate(-50%, -50%);

          display: block;
        }
      }
    }
  }
  &__label {
    cursor: pointer;
    user-select: none;

    margin-left: 8px;

    font-size: var(--button-desktop-lite-font-size);
    font-weight: var(--button-desktop-lite-font-weight);
    line-height: var(--button-desktop-lite-line-height);
    color: var(--gray-800);
    letter-spacing: var(--button-desktop-lite-letter-spacing);

    &_checked {
      color: var(--gray-800);
    }
  }
}

@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.switch-container{
  display: flex;
  align-items: center;
}

.switch-label-text {
  font-family: var(--button-desktop-lite-font-family);
  font-size: var(--button-desktop-lite-font-size);
  font-weight: var(--button-desktop-lite-font-weight);
  line-height: var(--button-desktop-lite-line-height);
  text-decoration: var(--button-desktop-text-decoration);
  margin-left: 8px;

  @include lower-mobile {
    font-family: var(--button-mobile-lite-font-family);
    font-size: var(--button-mobile-lite-font-size);
    font-weight: var(--button-mobile-lite-font-weight);
    line-height: var(--button-mobile-lite-line-height);
    text-decoration: var(--button-mobile-text-decoration);
  }
}

.switch-checkbox {
  height: 0;
  width: 0;
  visibility: hidden;
}

.switch-label {
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  width: 48px;
  height: 24px;
  background: transparent;
  border-radius: 100px;
  border: 1px solid var(--gray-100);
  position: relative;
  transition: var(--validation-success-main) .12s ease-out ;
}

.switch-button {
  content: '';
  position: absolute;
  top: 25%;
  left: 25%;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  transition: 0.2s;
  background: var(--gray-100);
  transform: translate(-50%, 0);
}
.switch-checkbox:checked + .switch-label {
  background-color: var(--validation-success-main);
  background-image: url('./icons/Check.svg');
  background-repeat: no-repeat;
  background-position: 30%;
  border: 1px solid transparent;
}

.switch-checkbox:checked + .switch-label .switch-button {
  left: calc(100% - 6px);
  transform: translateX(-100%);
  background: #fff;
}


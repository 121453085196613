@import "../../../../../mixin.scss";

.player-hud-container {
  position: absolute;
  z-index: 10000;
  bottom: 0;
  left: 0;

  display: grid;
  grid-template-areas: 'control time duration';
  grid-template-columns: 17vw 11vw auto;

  width: 100%;
  min-height: 68px;
  padding: 0 16px;

  background: linear-gradient(
    180deg,
    rgb(0 0 0 / 0%) 0%,
    rgb(0 0 0 / 80%) 80.73%
  );

  &-time {
    display: flex;
    justify-content: center;
    align-items: center;
    color: var(--gray-50);
    grid-area: time;
  }

  &-duration {
    grid-area: duration;
    height: 100%;
    align-items: center;
  }


  @include lower-tablet {
    grid-template-areas: 
      'time duration duration duration'
      '. control control .';
    grid-template-columns: 11vw repeat(2, auto) 11vw;
    gap: 2vw;
    padding: 0 16px 16px;
  }
  @include lower-mobile {
    grid-template-areas: 
      'duration duration'
      'time time'
      'control control';
    grid-template-columns: auto;
    gap: 2vw;
    padding: 0 16px 16px;
  }
}

.player-hud-icon {
  font-size: 28px;
  color: var(--gray-50);
}


@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.editBar {
  display: flex;
  background: var(--surface-front);;
  min-width: 589px;
  min-height: 48px;
  border-radius: 8px;
}

.button {
  display: flex;
  justify-content: center;
  align-items: center;
  max-height: 48px;
  min-width: fit-content;
  box-sizing: border-box;
  width: 100%;
  padding: 12px;
  color: var(--gray-0);
  font-weight: var(--button-desktop-default-font-weight);
  font-size: var(--button-desktop-default-font-size);
  font-family: var(--button-desktop-default-font-family);
  font-style: normal;
  line-height: var(--button-desktop-default-line-height);
  letter-spacing: var(--letter-spacing-m0-28);
  background: var(--interactive-default);
  text-decoration: var(--button-desktop-default-text-decoration);
  border: 1px solid transparent;
  cursor: pointer;
  transition: all 0.12s ease-out;
  gap: 8px;


  &-uncolored {
    color: var(--gray-800);
    background: transparent;
    &:hover, &:active, &.active{
      color: var(--interactive-hover);
    }

    &:disabled{
      background: transparent;
      color: var(--gray-800);
      opacity: 0.25;
    }
  }

  @include lower-tablet {
    font-size: var(--text-tablet-x3-5-weight-2-font-size);
  }

  @include lower-mobile {
    .button {
      font-weight: var(--button-mobile-default-font-weight);
      font-family: var(--button-mobile-default-font-family);
      line-height: var(--button-mobile-default-line-height);
      text-decoration: var(--button-mobile-default-text-decoration);
    }
  }
}

.mini-map {
  cursor: grab;

  position: fixed;
  z-index: 20;
  bottom: 20px;
  left: 20px;

  overflow: hidden;

  width: 312px;
  height: 172px;

  background: var(--gray-unchangeable-50);
  border-radius: 8px;

  &_horizontal {
    width: 386px;
    max-width: 30%;
    height: 232px;
    max-height: 20%;
  }

  &_vertical {
    width: 232px;
    max-width: 20%;
    height: 318px;
    max-height: 30%;
  }

  &_square {
    width: 298px;
    max-width: 30%;
    height: 298px;
    max-height: 30%;
  }

  &.mobile {
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  &.full__active {
    bottom: 0;
    left: 0;

    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;

    border-radius: 0;
  }

  &__wrapper {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    width: 100%;
    height: 100%;
  }

  @media all and (max-width: 768px) {
    right: 0;
    bottom: -30px;
    left: 0;

    overflow: hidden;

    width: 42vw;
    max-width: 42vw;
    height: 42vw;
    max-height: 42vw;
    margin-right: auto;
    margin-left: auto;

    border-radius: 50%;
  }
}

.scheme-canvas-container {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: 100%;
}

.scheme-canvas-container .konvajs-content {
  overflow: hidden;

  margin: 0 auto;

  border-radius: 8px;
}

.scheme-canvas-container > div:first-child {
  position: absolute;
  top: 0;
  left: 0;

  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  height: 100%;

  background: #fff;
  border-radius: 8px;
}

.left-panel_scheme {
  position: absolute;
  z-index: 1000;
  top: 45%;
  left: 20px;
  transform: translateY(-50%);

  display: flex;
  flex-direction: column;
  gap: 20px;
}

.container {
  pointer-events: none;

  z-index: 1000;

  display: flex;
  justify-content: center;

  width: fit-content;
}

@import 'oelp-tokens/css/variables';

.modal {

  &-wrapper {
    position: absolute;
    z-index: 10000;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.4);

    &-window {
      position: relative;
      z-index: 10010;
    }
  }
}
.message-server-error {

    &-head{
      display: flex;
      flex-direction: row;
      justify-content: center;
      width: auto;
      min-width: 400px;
      min-height: 100px;
      height: auto;

      &-title {
        top: 1px;
        left: 1px;
        letter-spacing: -0.3px;
        align-items: center;
        margin: 10px;

        font-weight: bold;
        font-size: 15px;
        line-height: 24px;
      }
    }

    &-wrapper {
      width: auto;
      min-width: 400px;
      height: auto;
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      padding: 24px;
      //color: $LightActive;
      //font-size: $fontSizeDefault;
      //font-family: $FontFamilyDefault;
      font-style: normal;
      line-height: 24px;
      letter-spacing: -0.28px;
      background: $surface-front;
      border-radius: 12px;
      //box-shadow: $ShadowActive;
    }

    &-close-btn {
      position: absolute;
      top: 1px;
      right: 1px;
      margin: 5px;
      //color: $LightFront;
      background-color: $heat-status-red !important;
      font-size: 16px;
      letter-spacing: -0.28px;
      line-height: 18px;
      padding: 8px;
      height: 34px;
      border: none;
      border-radius: 8px;
      width: 34px;
    }

}

.container {
    display: grid;
    justify-content: space-between;
    align-items: center; 
    grid-row-gap: 4px;
    grid-column-gap: 7px;
    grid-template-areas: 
        'title close-button'
        'subtitle close-button';

    &-fullscreen {
        & > h3, & > p {
            display: -webkit-box;
            -webkit-line-clamp: 1; 
            -webkit-box-orient: vertical;
            overflow: hidden;
            margin: 0;
        }
    
    }

    &-back-button-includes {
        grid-template-areas: 
        'back-button title close-button'
        'back-button subtitle close-button';
    }

    &-back-button, &-close-button {
        padding: 0!important;
        & > i {
            padding: 0;
            margin: 0;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }

    &-back-button {
        grid-area: back-button;
    }

    &-close-button {
        grid-area: close-button;
        outline: none;
    }

    &-title, &-subtitle {
        justify-self: baseline;
        text-align: left;
        line-height: 1;
        display: -webkit-box;
        -webkit-line-clamp: 2; 
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    &-title {
        grid-area: title;
        margin: 0;
    }

    &-subtitle {
        grid-area: subtitle;
        margin: 0;
    }
  }

@import 'oelp-tokens/css/variables';

.profile-header {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;

    @media all and (max-width: 767px) {
        margin: 0;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        gap: 16px
    }

        &_info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
        }

        &-name{
            font-size: $font-size-x5;
            font-weight: 600;
            margin-bottom: 0;
            letter-spacing: -0.28px;
            }
        &_avatar {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-color: rgba(0, 0, 0, 0.2);
            background-image: url('./avatar.png');
            background-size: cover;
            cursor: pointer;
        }

        &_mask {
            width: 60px;
            height: 60px;
            border-radius: 50%;
            background-position: center;
            background-size: auto;
            cursor: pointer;

            &:hover {
                @keyframes camera-animation{
                from {
                    background-color: rgba(0, 0, 0, 0);
                    background-image: none;
                }
                to {
                    // background-image: url("../../../../icons/camera.svg");
                    background-color: rgba(0, 0, 0, 0.2);
                }
            }
                animation: camera-animation;
                animation-timing-function: ease;
                animation-duration: 0.2s;
                // background-image: url("../../../../icons/camera.svg");
                background-color: rgba(0, 0, 0, 0.2);
            }
        }
        &_input{
            margin: 10px;
            display: none;
        }

        &-email {
            color: $gray-300;
        }

        @media all and (max-width: 767px) {

            &_avatar{
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-image: url('./avatar.png');
                background-size: cover;
            }

            &_mask {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: rgba(0, 0, 0, 0.2);
                background-size: cover;
                cursor: pointer;
            }
            &_item{
               // margin: 5px;
                // background-image: url("../../../../icons/camera.svg");
            }

            &_info {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            &-name{
                font-size: $font-size-x4;
                font-weight: 600;
                margin-bottom: 0;
                letter-spacing: -0.28px;
            }
        }
}


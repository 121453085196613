@import 'oelp-tokens/css/variables';
.photos-switcher-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
  border-radius: 8px;
  padding: 16px 0;
  // margin-left: -16px;
  background: $surface-front;
  width: 100%;

  &__title{
    position: relative;
    left: 20px;
    color: var(--gray-400);
  }

  .photos-switcher-controls-wrapper {
    display: flex;
    gap: 12px;
    flex-direction: column;
    // padding-bottom: 16px;
    // margin-right: 16px;
    .photoswitcher-edit-button, .photoswitcher-add-photo-button{
      max-width: 418px;
      align-self: center;
    }
  }
}
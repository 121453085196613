@import 'oelp-tokens/css/variables';

.content-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
  ::-webkit-scrollbar {
    display: none;
  }
  .header {
    display: flex;
    width: 100%;

  }

  .filters {
    display: flex;
    flex-direction: column;
    max-height: calc(100% - 55px);
    overflow-y: auto;
    .body {
      display: flex;
      flex-direction: column;
      gap: 26px;
      flex: 1;

      &-content-wrapper {
        display: flex;
        width: 100%;
        flex-direction: column;

        .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          white-space: nowrap;
          margin-top: 6px;
          margin-bottom: 6px;
        }

        .btn-clear {
          max-height: 23px;
          width: auto;
        }
      }
    }
  }
}

.bottom__panel{
  position: absolute;
  width: 100%;
  bottom: 0;
}
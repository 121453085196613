.dropdown-menu-container{
  z-index: 2000;
  max-width: fit-content;
  cursor: pointer;
  .dropdown-menu-trigger{
    cursor: pointer;
    display: flex;
    width: fit-content;
    z-index: 2000;
    background: transparent;
    position: absolute;
  }
  .dropdown-menu-content{
    background: transparent;
    width: fit-content;
    position: absolute;
    cursor: pointer;
  }
}
@import 'oelp-tokens/css/variables';
.zoom-button-block {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: $surface-front;
  border-radius: 8px;
  width: 40px;
  &-divider {
    width: 100%;
    height: 2px;

    background-color: var(--gray-200);
  }

  &_with-gap {
    gap: 3px;
  }

  @media screen and (max-width: 768px) {
    left: 16px;
  }
}

.subheader {
    &-weight {
        &-3 {
            @include typography-options(
                var(--subheader-desktop-weight-3-font-family),
                var(--subheader-desktop-weight-3-font-weight),
                var(--subheader-desktop-weight-3-line-height),
                var(--subheader-desktop-weight-3-font-size),
                var(--subheader-desktop-weight-3-letter-spacing)
            )
        }
        &-2 {
            @include typography-options(
                var(--subheader-desktop-weight-2-font-family),
                var(--subheader-desktop-weight-2-font-weight),
                var(--subheader-desktop-weight-2-line-height),
                var(--subheader-desktop-weight-2-font-size),
                var(--subheader-desktop-weight-2-letter-spacing)
                )
        }
        &-1 {
            @include typography-options(
                var(--subheader-desktop-weight-1-font-family),
                var(--subheader-desktop-weight-1-font-weight),
                var(--subheader-desktop-weight-1-line-height),
                var(--subheader-desktop-weight-1-font-size),
                var(--subheader-desktop-weight-1-letter-spacing)
            )
        }
    }
}


@import 'oelp-tokens/css/variables';
.gallery {
  transition: all 0.12s ease-out;

  &-wrapped {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: calc(100vh - 80px);
    @media all and (max-width: 1401px) {
      height: calc(100vh - 60px);
    }
    @media all and (min-width: 2048px) {
      padding-bottom: 0;
    }

    .image-gallery {
      display: flex;
      flex-direction: column;
      width: 100%;
      max-height: 100%;
      align-items: center;
      justify-content: space-between;
      height: inherit;

      .vertical-image {
        height: inherit!important;
      }

      //padding: 1vh;
      //height: 100%;
      
      &-content {
        display: flex;
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
        width: 100%;
        gap: 15px;
        max-width: 100%;
        // padding: 50px 0 25px 0;
        // padding: 50px 0 25px 0;
        // @media all and (min-width: 1921px) {
        //   gap: 60px;
        // }
        // @media all and (min-width: 2048px) {
        //   gap: 65px;
        // }
        // @media all and (min-width: 2561px) {
        //   gap: 70px;
        // }
        // @media all and (min-width: 3201px) {
        //   gap: 75px;
        // }

        .image-gallery-slides {
          height: 95%;
          border-radius: 16px;

          > .image-gallery-slide {
            height: 100%;
            padding: 0 100px 0 100px;
            img {
              // height: initial;
              height: 80%;
              // height: inherit;

              // height: 100%;
            }

            > .verical-image {
              height: inherit;
            }
          }
        }
        .image-gallery-swipe {
          height: 95%;

          > .image-gallery-slides {
            height: 100%;
            border-radius: 16px;

            > .image-gallery-slide {
              height: 100%;
              padding: 0 100px 0 100px;

              > .verical-image {
                height: inherit;
              }
              img {
                // height: 100%;

              }
            }
        }
      }


        .image-gallery-slide {

          &-wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }

          img.image-gallery-image {
            height: 100%;

            width: auto;
            max-width: 100%;
            max-height: 586px;
            border-radius: 16px;
            object-fit: cover;
          }
        }
      }
      .image-gallery-thumbnails{
        display: flex;
        justify-content: center;
      }
      .image-gallery-thumbnails-container {
        display: flex;
        flex-wrap: nowrap;
        transform: none !important;
        align-items: center;
        transition: all 120ms ease-out;
        overflow-x: auto;
        padding: 5px 0 5px 0;
        &:hover{

        }

        @media all and (max-width: 566px){
          display: flex;
          align-items: center;
          position: fixed;
          bottom: 0;
        }
        .image-gallery-thumbnail {
          display: flex;
          position: relative;
          width: 13vw;
          min-width: 13vw;
          max-height: 12vh!important;
          min-height: 12vh;
          // width: 350px;
          // height: 190px;
          margin-left: 12px;
          overflow: hidden;
          background: $surface-front;
          border: none;
          border-radius: 16px;
          cursor: pointer;


          img {
            width: 13vw;
            min-width: 13vw;
            max-height: 12vh!important;
            height: 12vh!important;
            object-fit: cover;
            object-position: center;
          }

          &::after {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: $gray-50;
            opacity: 0.8;
            transition: all 0.12s ease-out;
            content: '';
          }
          &:hover::after{
            opacity: 1;
            width: 100%;
            height: 100%;
            background: none;
          }
          &.active {
            border: none;

            &::after {
              opacity: 0;
            }
          }
        }
      }
    }
    .image-gallery-custom-nav-wrapper {
      &-right, &-left {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: absolute;
        width: 100%;
        max-width: 20%;
        height: 90%;
        max-height: 100%;
        z-index: 2;
        cursor: pointer;
      }


      &-right {
        right: 40px;

      }
      &-left {
        left: 40px;
        transform: rotate(180deg);
      }
    }

  }
}

.arrow-icon-with-hover{
  transition: all 0.12s ease-out;
  fill: #74797D;
  :hover{
    fill: $interactive-default;
  }
}

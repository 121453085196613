@import 'oelp-tokens/css/variables';
@import 'src/fonts/icons-font/style.scss';

.input-field {
    &-label {
        width: 100%;
        color: $gray-400;
        font-weight: normal;
        font-size: $font-size-x4;
        font-family: $font-families-main;
        font-style: normal;
        line-height: 16px;
        position: relative;
        overflow: hidden;
        box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06);

        &.label-list-view-bs {
            box-shadow: 0 0 0 1px $gray-100;
            border-radius: 8px;
        }

        .input-suggestion {
            @extend .text;
            position: absolute;
            display: inline-block;
            white-space: nowrap;
            top: 50%;
            left: 56px;
            transform: translate(0%,-50%);
            color: $gray-800;
            opacity: 0.18;
            width: 100%;
            word-wrap: break-word;
            overflow: hidden;
        }
    }

    &-invalid-field {
        display: inline-block;
        margin-top: 4px;
        padding-left: 8px;
        color: $gray-800;
        font-weight: normal;
        font-size: $font-size-x4;
        font-family: $font-families-main;
        font-style: normal;
        line-height: 18px;
        letter-spacing: -0.28px;
    }

    &-input {
        @extend .text;
        color: $gray-800;
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        min-height:48px;
        max-height: 48px;
        padding: 12px 40px;

        border: 1.4px solid $gray-0;
        border-radius: 8px;
        outline: none;
        transition: all 0.12s ease-out;

        &-icon-search {
            position: absolute;
            top: 10px;
            left: 16px;
            color: #000;
        }

        &-filter-tag{
            position: absolute;
            top: 4px;
            left: 60px;
        }

        &-close-btn{
            position: absolute;
            right: 0;
            top: 6px;
        }

        &::placeholder {
            color: $gray-200;
        }

        &.error {
            border: 1.4px solid $validation-error-main;
        }
    }
}

.text {
    font-weight: normal;
    font-size: $font-size-x4;
    font-family: $font-families-main;
    font-style: normal;
    line-height: 18px;
    letter-spacing: -0.28px;
}
@import 'oelp-tokens/css/variables';
@import "../../../mixin.scss";

.container {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: calc(23%);
    overflow: hidden;
    max-width: 480px;
    min-width: 100%;
    min-height: 420px;
    border-radius: 8px;

    background-color: var(--surface-mid);

    border-bottom: 12px solid var(--surface-mid);

    box-shadow: var(--shadow-active-desktop-x) var(--shadow-active-desktop-y) var(--shadow-active-desktop-blur) var(--shadow-active-desktop-color);

    cursor: pointer;
    
    @include lower-tablet-landscape {
        width: 450px;
    }

    @include lower-tablet {
        width: 100%;
        min-width: auto;
        border-bottom: none;
    }
}

.selected {
    border-bottom: 12px solid var(--interactive-default);

    @include lower-tablet {
        border-bottom: none;
    }
}

.map-layer {
  &-panel {
    &-left {
      display: flex;
      flex-direction: column;
      gap: 20px;
    }

    &-right {
      &-bottom {
        &_mini {
          right: 12px;
          bottom: 12px;
        }
      }

      &-top {
        &_mini {
          top: 12px;
          right: 12px;
        }
      }
    }
  }

  &-marker {
    &-tooltip {
      overflow: hidden;

      width: 180px;
      height: 84px;

      background: var(--surface-front);
      border-radius: 8px;
      box-shadow: var(--shadow-elevation-popup);

      & > img {
        width: 100%;
        height: 100%;
      }
    }
  }
}

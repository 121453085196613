@import 'oelp-tokens/css/variables';

//$formSizeDesktop: 660px;

.login {
    &-wrapper {
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;

        input::-ms-reveal,
        input::-ms-clear {
            display: none;
        }

        background-color: rgba(0, 0, 0, .9);

        @media all and (min-width: 785px) {
            padding-top: 0;
        }

        & > img {
            opacity: .7;
        }
    }

    &-logo {
        position: relative;
        z-index: 20;
        display: flex;
        align-items: flex-end;
        justify-content: center;

        @media all and (min-width: 785px) {
            margin-bottom: 64px;
        }

        &-text {
            color: $interactive-default;
            font-weight: 600;
            font-size: $font-size-x4;
            font-family: $font-families-main;
            font-style: normal;
            line-height: 22px;
            letter-spacing: -0.2px;
            margin-left: 12px;

            @media all and (min-width: 785px) {
                color: $surface-mid;
                font-size: 29px;
                line-height: 29px;
                margin-left: 11.51px;
            }
        }
    }

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        z-index: 10;
        display: none;
        width: 100%;
        height: 100%;
        object-fit: cover;

        @media all and (min-width: 785px) {
            display: block;
        }
    }
}

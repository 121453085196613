@import 'oelp-tokens/css/variables';

.locationCard {

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  min-width: 294px;
  min-height: 164px;
  background: var(--surface-front);
  border-radius: 12px;

  .image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 164px;
    gap: 2px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    cursor: pointer;
    transition: all 120ms ease-out;

    &:hover {
      background: $surface-blackout;
    }

    i {
      display: flex;
      width: 24px;
      height: 18px;
      color: #FFFFFF;
    }

    .hover-text {
      display: flex;
      justify-content: center;
      color: #FFFFFF;
      padding: 0;
      font-family: var(--font-families-main);
      font-size: $font-size-x3;
      font-weight: var(--text-desktop-x4-weight-2-font-weight);
      line-height: var(--line-heights-x4);
    }
  }

  .text {
    display: flex;
    margin: 12px 16px 16px;
    font-family: var(--font-families-main);
    font-size: var(--font-size-x3-5);
    font-weight: var(--text-desktop-x4-weight-2-font-weight);
    line-height: var(--line-heights-x4);
  }
}

.image {
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 8px;

  > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }
  &-card {
    max-height: 180px;
  }
  &-sidebar {
    max-height: 200px;
  }
}

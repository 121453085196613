@import "oelp-tokens/css/variables";

.profile-menu {
  max-width: 280px;
  width: 100%;
  position: absolute;
}

.profile-menu-header{
  display: flex;
  max-width: 280px;
  width: 100%;
  position: absolute;
  align-items: center;
  padding: 12px 24px 12px !important;
  gap: 16px;
  background: $surface-front;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  cursor: pointer;
  top: 60px;
  &-avatar {
    width: 60px;
    height: 60px;
  }

  &-content{
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    .email{
      padding: 0;
      margin: 0;
      font-family:  var(--subheader-desktop-weight-3-font-family);
      color: $gray-100;
      font-weight:  var(--subheader-desktop-weight-3-font-weight);
      font-size:  var(--subheader-desktop-weight-3-font-size);
      line-height: var(--subheader-desktop-weight-3-line-height);
      letter-spacing: var(--subheader-desktop-weight-3-letter-spacing);
    }
    .name{
      padding: 0;
      margin: 0;
      font-weight:  var(--title-desktop-h4-weight-1-font-weight);
      font-size:  var(--title-desktop-h4-weight-1-font-size);
      line-height: var(--title-desktop-h4-weight-1-line-height);
      letter-spacing: var(--title-desktop-h4-weight-1-letter-spacing);
    }
  }
}


.trigger-avatar-container{
  position: absolute;
  width: 44px;
  height: 44px;
  cursor: pointer;
}

.profile-menu-content{
  position: absolute;
  top: 143px;
  max-width: 280px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 12px;
  background: $surface-front;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
}

@import 'oelp-tokens/css/variables';

.container {
  display: flex;
  align-items: center;
  gap: 6.29px;
  color: var(--gray-400);
  max-width: fit-content;
  width: 100%;
  margin-bottom: 13px;
}

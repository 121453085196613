@import 'oelp-tokens/css/variables';

.search-item {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 8px 12px;
  transition: all 0.12s ease-out;
  gap: 10px;
  border-radius: 8px;
  cursor: pointer;

  &:hover {
    background: $interactive-light-hover;
  }

  &-active {
    background: $interactive-light-hover;
  }

  &-text {
    color: $gray-900;
    font-family: var(--button-desktop-lite-font-family);
    font-weight: var(--button-desktop-lite-font-weight);
    line-height: var(--button-desktop-lite-line-height);
    font-size: var(--button-desktop-lite-font-size);
    letter-spacing: var(--button-desktop-lite-letter-spacing);
    text-decoration: var(--button-desktop-lite-text-decoration);
    // margin-top: 10px;
    &-bold {
      color: $gray-900;
      font-family: var(--button-desktop-lite-font-family);
      line-height: var(--button-desktop-lite-line-height);
      font-size: var(--button-desktop-lite-font-size);
      letter-spacing: var(--button-desktop-lite-letter-spacing);
      text-decoration: var(--button-desktop-lite-text-decoration);
      font-weight: var(--title-desktop-h4-weight-1-font-weight);
    }
  }

  &-search-tooltip {
    color: $gray-900;
    font-family: var(--caption-desktop-x2-75-weight-3-font-famil)y;
    font-weight: var(--caption-desktop-x2-75-weight-3-font-weight);
    line-height: var(--caption-desktop-x2-75-weight-3-line-height);
    font-size: var(--caption-desktop-x2-75-weight-3-font-size);
    letter-spacing: var(--caption-desktop-x2-75-weight-3-letter-spacing);
    text-decoration: var(--caption-desktop-x2-75-weight-3-paragraph-spacing);

    &-bold{
      color: $gray-900;
      font-family: var(--title-desktop-h4-weight-1-font-family);
      font-weight: var(--title-desktop-h4-weight-1-font-weight);
      line-height:  var(--title-desktop-h4-weight-1-line-height);
      font-size: var(--caption-desktop-x2-75-weight-3-font-size);
      letter-spacing: var(--title-desktop-h4-weight-1-letter-spacing);
      text-decoration: var(--title-desktop-h4-weight-1-text-decoration);
    }
  }
}

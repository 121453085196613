@import 'oelp-tokens/css/variables';

.container {
    display: flex;
    width: 240px;
    align-items: center;
    max-height: 48px;
    color: var(--gray-800);
    font-weight: var(--font-weights-medium);
    border-radius: 8px;
    background-color: var(--surface-front);
    box-shadow: 0px 2px 12px rgb(2 8 32 / 12%);
}

@import 'oelp-tokens/css/variables';

.zoneShootingCard {

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 0;
  min-width: 230px;
  overflow: hidden;
  height: fit-content;
  min-height: 164px;
  background: var(--surface-front);
  border-radius: 12px;
  transition: all 0.12s ease-out;
  
    .image {
    display: flex;
    position: absolute;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    min-height: 164px;
    gap: 2px;
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
    cursor: pointer;
    transition: all 120ms ease-out;

    &:hover {
      background: $surface-blackout;
    }

    i {
      display: flex;
      width: 24px;
      height: 18px;
      color: #FFFFFF;
    }
  }

  &:active, &.active {
    color: var(--interactive-default);
    border-bottom: 2px solid var(--interactive-default);
    box-shadow: var(--shadow-desktop-default-x) var(--shadow-desktop-default-y) var(--shadow-desktop-default-blur) var(--shadow-desktop-default-spread) var(--shadow-desktop-default-color);
  }

}

$breakpoint-desktop-ultra-large: 2048px;
$breakpoint-desktop-large: 1921px;
$breakpoint-desktop-middle: 1600px;
$breakpoint-desktop-small: 1367px;
$breakpoint-tablet-landscape: 1200px;
$breakpoint-tablet-portrait: 960px;
$breakpoint-mobile-landscape: 640px;
$breakpoint-mobile-portrait: 500px;



// Mobile
@mixin lower-mobile {
    @media (max-width: $breakpoint-mobile-portrait) { @content; }
}

@mixin greater-mobile {
    @media (min-width: $breakpoint-mobile-portrait) { @content; }
}

// Mobile landscape
@mixin lower-mobile-landscape {
    @media (max-width: $breakpoint-mobile-landscape) { @content; }
}

@mixin greater-mobile-landscape {
    @media (min-width: $breakpoint-mobile-landscape) { @content; }
}


// Tablet
@mixin lower-tablet {
    @media (max-width: $breakpoint-tablet-portrait) { @content; }
}

@mixin greater-tablet {
    @media (min-width: $breakpoint-tablet-portrait) { @content; }
}

// Tablet landscape
@mixin lower-tablet-landscape {
    @media (max-width: $breakpoint-tablet-landscape) { @content; }
}

@mixin greater-tablet-landscape {
    @media (min-width: $breakpoint-tablet-landscape) { @content; }
}

// Desktop small
@mixin lower-desktop-small {
    @media (max-width: $breakpoint-desktop-small) { @content; }
}

@mixin greater-desktop-small {
    @media (min-width: $breakpoint-desktop-small) { @content; }
}

// Desktop middle
@mixin lower-desktop-middle {
    @media (max-width: var(--breakpoint-desktop-middle)) { @content; }
}

@mixin greater-desktop-middle {
    @media (min-width: var(--breakpoint-desktop-middle)) { @content; }
}

// Desktop large
@mixin lower-desktop-large {
    @media (max-width: var(--breakpoint-desktop-large)) { @content; }
}

@mixin greater-desktop-large {
    @media (min-width: var(--breakpoint-desktop-large)) { @content; }
}

// Desktop ultra large
@mixin lower-desktop-ultra-large {
    @media (max-width: var(--breakpoint-desktop-ultra-large)) { @content; }
}

@mixin greater-desktop-ultra-large {
    @media (min-width: var(--breakpoint-desktop-ultra-large)) { @content; }
}


// ===========================================


// typography
@mixin typography-options($font-family, $font-weight, $line-height, $font-size, $letter-spacing) {
    font-family: $font-family;
    font-weight: $font-weight;
    line-height: $line-height;
    font-size: $font-size;
    letter-spacing: $letter-spacing;
}

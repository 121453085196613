@import "../../mixin.scss";


.viewing-content-container {
  display: flex;
  gap:  12px;
  max-width: fit-content;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  &-mobile-list {
    width: 100%;
    height: fit-content;
    padding-left: 1.8rem;

    &-button {
      justify-content: start;
    }
  }

  &-no-content-tooltip {
    position: fixed;
    bottom: 10vh;
    margin: 0 auto;
    width: 256px;

    padding: 6px 8px;
    color: var(--gray-50);
    font-family: var(--text-desktop-x3-5-weight-1-font-family);
    font-size: var(--text-desktop-x3-5-weight-1-font-size);
    font-weight: var(--text-desktop-x3-5-weight-1-font-weight);
    line-height: var(--text-desktop-x3-5-weight-1-line-height);
    background: var(--surface-dark-blackout);
    border-radius: 8px;

    text-align: center;

    opacity: 0;
    transition: all .12s ease-out;

    z-index: 5000;

    &-active {
      animation: clipboard-tooltip 3s ease;
    }

    @keyframes clipboard-tooltip {
      10% { opacity: 1; }   
      60% { opacity: 1; }   
      95% { opacity: 0; }
    } 
  }
}

.no-content-tooltip {
  position: fixed;
  bottom: 12vh;
  margin: 0 auto;
  width: 256px;

  padding: 6px 8px;
  color: var(--gray-50);
  background: var(--surface-dark-blackout);
  border-radius: 8px;

  text-align: center;

  opacity: 0;
  transition: all .12s ease-out;

  &-active {
    animation: clipboard-tooltip 3s ease;
  }
}

@import 'oelp-tokens/css/variables';

.profile-form {

    &-input-container {
        display: block;
        position: relative;
        color: $gray-400;
        font-size: $font-size-x3-25;
        letter-spacing: -0.2px;
    }

    input::-ms-reveal,
    input::-ms-clear {
        display: none;
    }

    ::placeholder{
        color: $gray-100;
    }

    &-button {
    width: 295px;
    height: 44px;
    border: none;
    outline: none;
    }

    &-input {
    display: block;
    padding: 15px 16px;
    width: 288px;
    height: 48px;
    background-color: transparent;
    color: $gray-800;
    font-size: $font-size-x4;
    letter-spacing: -0.28px;
    border: 1.4px solid #E9E9E9;
    box-sizing: border-box;
    border-radius: 8px;

        &-icon-password {
            position: absolute;
            top: 10px;
            right: 16px;
            width: 24px;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;

            &.show {
                background-image: url(../../../icons/form-password-eye-show.svg);
            }
            &.hide {
                background-image: url(../../../icons/form-password-eye-hide.svg);
            }
        }
    }

}

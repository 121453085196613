@import 'oelp-tokens/css/variables';

.container, .container__reset {
    position: absolute;
    top: calc(50% - 80px);
    left: 20px;
    width: 40px;
    z-index: 500;
    background-color: $surface-front;

    .h-line {
        width: 100%;
        height: 2px;
        background-color: $gray-50;
    }
}
.container__reset {
    height: 40px; 
    width: 40px;
    top: calc(50% + 30px);

    &-tooltip {
        width: max-content;
        &::before {
            top: 6px;
            left: -13px;
            border-width: 8px 5px 8px 6px;
            transform: rotate(90deg);
        }
    }
}

@import 'oelp-tokens/css/variables';

.loader {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 4000;
  width: 100%;
  background-color: var(--surface-back);
}

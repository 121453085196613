.poi-marker {
  &-container {
    position: relative;

    display: flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;

    will-change: opacity;

    &_medium {
      width: 24px;
      height: 24px;

      & > .poi-marker {
        &_dot {
          background-color: var(--interactive-default);
          width: 24px;
          height: 24px;
        }

        &_icon {
          width: 40px;
          height: 40px;
        }
      }
    }

    &:hover {
      cursor: pointer;

      & > .poi-marker {
        &_dot {
          background: var(--interactive-hover);
          border-color: var(--gray-unchangeable-0);
        }
      }
    }
  }

  &_dot {
    width: 24px;
    height: 24px;
    border-color: var(--gray-unchangeable-0);
    background: var(--gray-unchangeable-50);
    border-style: solid;
    border-width: 2px;
    border-radius: 50%;

    transition: background 0.12s ease-in-out, border-color 0.12s ease-in-out;
    display: flex;
    justify-content: center;
    align-items: center;

    & > svg {
      width: 14px;
      height: 13px;

      & > * {
        fill: var(--gray-unchangeable-0);
      }
    }
  }

  &_icon {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);

    width: 58px;
    height: 58px;
  }
}

@import 'oelp-tokens/css/variables';

.tooltip {
  &-container {
    margin: 0;
    padding: 0 !important;
    border: none !important;
    box-shadow: none !important;
    --tooltipBackground: none !important;

    &-content {
      padding: 6px 8px;
      background: var(--surface-dark-blackout);
      border-radius: 8px;
      letter-spacing: normal;
      white-space: normal;
      word-break: normal;
      word-spacing: normal;
      word-wrap: normal;
      color: var(--gray-100);

      z-index: 1;

      transition: all .12s ease-out;

      &-visible {
        opacity: 1;
      }

      &:before {
        content: '';
        position: absolute;
        display: block;
        top: -9px;
        width: 0;
        height: 0;
        border-style: solid;
        border-width: 5px 8px 5px 8px;
        border-color: black transparent transparent transparent;
        transform: rotate(180deg);
      }
    }
  }
}

.title {
    &-h1 {
        &-weight {
            &-3 {
                @include typography-options(
                    var(--title-desktop-h1-weight-3-font-family),
                    var(--title-desktop-h1-weight-3-font-weight),
                    var(--title-desktop-h1-weight-3-line-height),
                    var(--title-desktop-h1-weight-3-font-size),
                    var(--title-desktop-h1-weight-3-letter-spacing)
                )
            }

            &-2 {
                @include typography-options(
                    var(--title-desktop-h1-weight-2-font-family),
                    var(--title-desktop-h1-weight-2-font-weight),
                    var(--title-desktop-h1-weight-2-line-height),
                    var(--title-desktop-h1-weight-2-font-size),
                    var(--title-desktop-h1-weight-2-letter-spacing)
                )
            }
            
            &-1 {
                @include typography-options(
                    var(--title-desktop-h1-weight-1-font-family),
                    var(--title-desktop-h1-weight-1-font-weight),
                    var(--title-desktop-h1-weight-1-line-height),
                    var(--title-desktop-h1-weight-1-font-size),
                    var(--title-desktop-h1-weight-1-letter-spacing)
                )
            }
        }
    }

    &-h2 {
        &-weight {
            &-3 {
                @include typography-options(
                    var(--title-desktop-h2-weight-3-font-family),
                    var(--title-desktop-h2-weight-3-font-weight),
                    var(--title-desktop-h2-weight-3-line-height),
                    var(--title-desktop-h2-weight-3-font-size),
                    var(--title-desktop-h2-weight-3-letter-spacing)
                )
            }

            &-2 {
                @include typography-options(
                    var(--title-desktop-h2-weight-2-font-family),
                    var(--title-desktop-h2-weight-2-font-weight),
                    var(--title-desktop-h2-weight-2-line-height),
                    var(--title-desktop-h2-weight-2-font-size),
                    var(--title-desktop-h2-weight-2-letter-spacing)
                )
            }
            
            &-1 {
                @include typography-options(
                    var(--title-desktop-h2-weight-1-font-family),
                    var(--title-desktop-h2-weight-1-font-weight),
                    var(--title-desktop-h2-weight-1-line-height),
                    var(--title-desktop-h2-weight-1-font-size),
                    var(--title-desktop-h2-weight-1-letter-spacing)
                )
            }
        }
    }

    &-h3 {
        &-weight {
            &-3 {
                @include typography-options(
                    var(--title-desktop-h3-weight-3-font-family),
                    var(--title-desktop-h3-weight-3-font-weight),
                    var(--title-desktop-h3-weight-3-line-height),
                    var(--title-desktop-h3-weight-3-font-size),
                    var(--title-desktop-h3-weight-3-letter-spacing)
                )
            }

            &-2 {
                @include typography-options(
                    var(--title-desktop-h3-weight-2-font-family),
                    var(--title-desktop-h3-weight-2-font-weight),
                    var(--title-desktop-h3-weight-2-line-height),
                    var(--title-desktop-h3-weight-2-font-size),
                    var(--title-desktop-h3-weight-2-letter-spacing)
                )
            }
            
            &-1 {
                @include typography-options(
                    var(--title-desktop-h3-weight-1-font-family),
                    var(--title-desktop-h3-weight-1-font-weight),
                    var(--title-desktop-h3-weight-1-line-height),
                    var(--title-desktop-h3-weight-1-font-size),
                    var(--title-desktop-h3-weight-1-letter-spacing)
                )
            }
        }
    }

    &-h4 {
        &-weight {
            &-3 {
                @include typography-options(
                    var(--title-desktop-h4-weight-3-font-family),
                    var(--title-desktop-h4-weight-3-font-weight),
                    var(--title-desktop-h4-weight-3-line-height),
                    var(--title-desktop-h4-weight-3-font-size),
                    var(--title-desktop-h4-weight-3-letter-spacing)
                )
            }

            &-2 {
                @include typography-options(
                    var(--title-desktop-h4-weight-2-font-family),
                    var(--title-desktop-h4-weight-2-font-weight),
                    var(--title-desktop-h4-weight-2-line-height),
                    var(--title-desktop-h4-weight-2-font-size),
                    var(--title-desktop-h4-weight-2-letter-spacing)
                )
            }
            
            &-1 {
                @include typography-options(
                    var(--title-desktop-h4-weight-1-font-family),
                    var(--title-desktop-h4-weight-1-font-weight),
                    var(--title-desktop-h4-weight-1-line-height),
                    var(--title-desktop-h4-weight-1-font-size),
                    var(--title-desktop-h4-weight-1-letter-spacing)
                )
            }
        }
    }
}

.subheader {
    &-weight {
        &-3 {
            @include typography-options(
                var(--subheader-desktop-weight-3-font-family),
                var(--subheader-desktop-weight-3-font-weight),
                var(--subheader-desktop-weight-3-line-height),
                var(--subheader-desktop-weight-3-font-size),
                var(--subheader-desktop-weight-3-letter-spacing)
            )
        }

        &-2 {
            @include typography-options(
                var(--subheader-desktop-weight-2-font-family),
                var(--subheader-desktop-weight-2-font-weight),
                var(--subheader-desktop-weight-2-line-height),
                var(--subheader-desktop-weight-2-font-size),
                var(--subheader-desktop-weight-2-letter-spacing)
            )
        }

        &-1 {
            @include typography-options(
                var(--subheader-desktop-weight-1-font-family),
                var(--subheader-desktop-weight-1-font-weight),
                var(--subheader-desktop-weight-1-line-height),
                var(--subheader-desktop-weight-1-font-size),
                var(--subheader-desktop-weight-1-letter-spacing)
            )
        }
    } 
}

@import 'oelp-tokens/css/variables';

.sidebar {
  width: 100%;
  overflow: auto;
  background: var(--surface-front);
  display: flex;
  height: 100vh;
  flex-direction: column;
  max-width: 400px;
  background-color: var(--surface-front);
}

.sidebar-header {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  max-width: 100%;
  min-height: 56px;
  background-color: var(--surface-front);
  padding: 12px 8px 8px 20px;

  &__title {
    font-family: var(--title-desktop-h4-weight-3-font-family);
    font-size: var(--title-desktop-h4-weight-3-font-size);
    font-weight: var(--title-desktop-h4-weight-3-font-weight);
    line-height: var(--title-desktop-h4-weight-3-line-height);
    letter-spacing: var(--title-desktop-h4-weight-3-letter-spacing);
    color: var(--gray-900);

    margin: 0;
    padding: 0;
  }
}

.content-wrapper {
  display: flex;
  width: 100%;
  flex-direction: column;
  height: 100%;
  flex: 1;

  background-color: var(--surface-mid);

  .levels-cards {
    padding: 20px 20px 32px 20px;
    align-content: flex-start;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
    justify-content: space-between;
    width: 100%;
  }
}

.button {
  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 8px;
    width: 100%;
  }
}

.card {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 16px;
  background: $surface-front;
  border-radius: 8px;
  gap: 8px;

  &-value {
    // white-space: break-spaces;
  }

  &-title {
    font-family: var(--subheader-desktop-weight-1-font-family);
    font-size: var(--subheader-desktop-weight-1-font-size);
    font-weight: var(--subheader-desktop-weight-1-font-weight);
    line-height: var(--subheader-desktop-weight-1-line-height);
    letter-spacing: var(--subheader-desktop-weight-1-letter-spacing);
    color: var(--gray-400);
  }
  &-text {
    font-family: var(--text-desktop-x3-5-weight-1-font-family);
    font-size: var(--text-desktop-x3-5-weight-1-font-size);
    font-weight: var(--text-desktop-x3-5-weight-1-font-weight);
    line-height: var(--text-desktop-x3-5-weight-1-line-height);
    letter-spacing: var(--text-desktop-x3-5-weight-1-letter-spacing);
    color: var(--gray-700);
  }
  &-wrapper {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding-top: 12px;
    width: 100%;
  }
}

.image {
  border-radius: 8px;
  overflow: hidden;
}


.volume-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  align-items: center;

  width: 44px;
  height: 44px;
  padding: 14px;

  font-size: 28px;
  color: var(--gray-50);

  transition: width 0.2s;

  &-icon {
    &:hover {
      cursor: pointer;
    }
  }

  &_editable:hover {
    width: 244px;
    & .volume-container-icon {
      font-weight: 700;
      color: var(--interactive-default);
    }
  }
  &_editable:hover .volume-container-input {
    visibility: visible;
    opacity: 1;
  }

  &-input {
    width: 172px;

    visibility: hidden;
    opacity: 0;

    transition: opacity 0.2s;
  }
}

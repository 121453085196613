@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.coords-panel {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  top: 370px;
  bottom: auto;
  right: 16px;

  &-header {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 16px;
    padding: 0 8px;


    &-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0;
    }

    &-close_button {
      background-color: var(--heat-status-red) !important;
    }
  }

  &-input {
    display: block;
    width: 100%;
    height: calc(2.25rem + 2px);
    padding: 0.375rem 0.75rem;
    border: 1px solid #bdbdbd;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  &-save {
      width: 100%;
      display: flex;
      justify-content: flex-end;
  }

  &-container {
    position: relative;
    margin-top: 0px;
    top: 0;
    right: 0px;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: flex-start;
    justify-content:flex-start;
    order: 1;
    min-width: 80px;
    min-height: 80px;
    padding: 20px 12px;
    background: var(--surface-front);
    border-radius: 8px;
    user-select: none;
  }


  &-mobile-header{
    padding: 20px;
  }

  @include lower-tablet-landscape {
    top: auto;
    bottom: 26px;
    &-header {
      margin-bottom: 0;

      &-close_button {
        max-width: 26px!important;
        min-width: 26px!important;
        max-height: 26px!important;
        min-height: 26px!important;
      }
    }

    &-container {
      padding: 8px;
    }
  }
}





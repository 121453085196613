.timer-container {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  p {
    display: flex;
    justify-content: center;

    color: var(--interactive-default) !important;
  }

  &.timer-small {
    width: 22px;
    height: 22px;

    p {
      width: 22px;

      font-size: 14px;
    }
  }

  &.timer-medium {
    width: 32px;
    height: 32px;
    p {
      width: 32px;

      font-size: 16px;
    }
  }

  &.timer-large {
    width: 42px;
    height: 42px;
    p {
      font-size: 18px;
    }
  }

  svg {
    position: absolute;
    top: 0;
    left: 0;
    transform: rotateY(-180deg) rotateZ(-90deg);
  }
}

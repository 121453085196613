@import 'oelp-tokens/css/variables';


.container {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: start;


    background-color: var(--gray-50);
    z-index: 20;

    overflow: auto;


    .monitoring-view-project-container {
      padding: 120px 40px;

      h3 {
        margin-bottom: 20px;
      }
      
      .items-list {
        padding: 0!important;
        display: flex;
        flex-wrap: wrap;
        align-content: flex-start;
        gap: 28px;

        width: 100%;

        &-wrapper {
          display: flex;
          flex-direction: column;
        }
    }
  }
}

.content-wrapper-container {
  background-color: var(--gray-50);
  z-index: 2;
}


.header-wrapper{
    display: flex;
    flex: 1;
    z-index: 120;
    align-items: flex-start;
    justify-content: center;
    &-start{
      justify-content: flex-start;
    }
  }

  .info-wrapper {
    max-width: 400px;
    min-width: 350px;
    padding: 12px 16px;
    gap: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 8px;
    background-color: var(--surface-front);

    overflow: hidden;

    &-location {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    } 

    > h4 {
      margin: 0;
    }
  }
  .right-btns-wrapper {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
    flex: 1;
    .view-switcher-wrapper{
      max-width: fit-content;
    }
    &.contentStart{
      justify-content: start;
    }
  
    &.contentEnd {
      justify-content: end;
    }
  }

@import 'oelp-tokens/css/variables';

.button-group{
  display: flex;
  max-width: fit-content;
  align-items: center;
  border-radius: 8px;
  &.button-group-row{
    flex-direction: row;
  }
  &.button-group-col{
    justify-content: center;
    flex-direction: column;
  }
  &.button-group-gap-0{
    gap: 0
  }
  &.button-group-gap-4{
    gap: 4px
  }
  &.button-group-gap-8{
    gap: 8px
  }
  &.button-group-gap-12{
    gap: 12px
  }
  &.button-group-gap-16{
    gap: 16px
  }
  &.button-group-gap-20{
    gap: 20px
  }
  &.button-group-gap-24{
    gap: 24px
  }
  &.button-group-transparent {
    background: none;
  }
  &.contentStart{
    justify-content: start;
  }

  &.contentEnd {
    justify-content: end;
  }
  &.contentCenter{
    justify-content: center;
  }
  &.contentBetween{
    justify-content: space-between;
  }
  &.w-fill{
    max-width: 100%;
  }
  &.w-content{
    max-width: fit-content;
  }
}



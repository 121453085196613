@import 'oelp-tokens/css/variables';
.container {
  overflow-y: auto;
  display: flex;
  height: 100vh;
  background: $surface-mid;
  flex-direction: column;
  font-family: $font-families-main;
  min-width: 490px;
  max-width: 490px;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;
  position: relative;
  gap: 16px;

}

.container::-webkit-scrollbar {
  display: none;
}
@import 'oelp-tokens/css/variables';

.profile-page {
    header {
        display: none;
    }
    &-container-outer {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        width: 100vw;
        height: 100vh;
        z-index: 3000;
        min-width: 100%;
        // font-family: $FontFamilyDefault;
        color: $gray-800;
        padding: 16px;
        background-color: $surface-mid;
        @media all and (max-width: 767px) {
            position: relative;
            width: 100%;
            justify-content: flex-start;
            padding: 0 16px;
            flex-direction: column;
            align-items: center;
            margin-top: 30px;
            width: 100%;
            height: auto;
        }
    }

    &-container-inner {
        position: relative;
        width: 1240px;
        min-height: 100vh;
        margin: 0 auto;
        box-sizing: border-box;
        // font-family: $FontFamilyDefault;
        color: $gray-800;
        display: grid;
        background-color: $surface-mid;
        grid-template-columns: 1fr 3fr 1fr;
        grid-template-rows: 100px 1fr;
        grid-template-areas:
        "none content content"
        "menu content content";

        @media all and (max-width: 767px) {
            display: flex;
            flex-direction: column;
            width: 100%;
            padding: 0 16px;
        }

        .sidebar {
            grid-area: menu;
            width: 210px;
            margin-right: 40px;

            @media all and (max-width: 767px) {
                width: 100%;
            }
        }

        .menu-content {
            width: 100%;
            height: 170px
        }

        .navigation {
            grid-area: nav;
            display: flex;
            align-items: center
        }

        .button-back {
            position: absolute;
            top: 24px;
            right: 0px;
            grid-area: back;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            gap: 16px;
        }

        .profile-page-content {
            grid-area: content;
            padding: 0;
            display: grid;
            grid-template-rows: 100px 1fr;
            grid-template-areas:
                "navigation"
                "favourites";

            @media all and (max-width: 767px) {
                display: flex;
                flex-direction: column;
                gap: 24px;
            }
        }
    }

    &_content {
        display: flex;
        flex-direction: row;
        width: 900px;
        padding: 0 90px;

        @media all and (max-width: 767px) {
            flex-direction: column;
            align-items: center;
            margin: 0;
        }
    }

    &_bio {
        display: flex;
        flex-direction: column;
        padding-top: 100px;
        align-items: flex-start;

        @media all and (max-width: 767px) {
            margin: 0;
            padding: 0;
        }
    }

    &-header-container {
        margin-bottom: 36px;

        @media all and (max-width: 767px) {
            margin-top: 40px;
        }
    }

    &-menu {
        display: flex;
        width: 200px;

        @media all and (max-width: 767px) {
            justify-content: center;
            width: 100%;
            margin-bottom: 40px;
            width: 100%;
            margin: 0;
        }
    }

    &-menu-button {
        display: flex;
        flex-direction: row;
        height: 40px;
        margin: 24px auto 8px auto;
        background-color: transparent;
        outline: none;
        border: none;
    }

    &-menu-icon {
        margin-left: 8px;

        &-container {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 24px;
            height: 24px;
        }
    }

    &_backButton {
        display: flex;
        flex-direction: row;
        gap: 8px;
        height: 32px;
        justify-content: center;
        align-items: center;
        padding: 7px 12px;
        font-size: $font-size-x4;
        line-height: 18px;
        letter-spacing: -0.28px;
        background: $surface-front;
        box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06);
        border-radius: 8px;
        border: 0;
        outline: none;

        &:hover {
            background: #b4ceff;
            box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06)Hover;
        }

        &:active {
            box-shadow: 0 2px 12px rgba(5, 18, 70, 0.06)Active;
            opacity: 0.9;
        }
    }
}


@import 'oelp-tokens/css/variables';
@import "../../mixin.scss";

.editor-menu {
  transform: translateY(-160%)!important;
}

.button-start {
  @include lower-tablet {
    width: 196px;
  }
}

.editor-menu-content {
  position: absolute;
  background: var(--surface-front);
  border-radius: 8px;
  bottom: 10px;
  padding: 4px;

  &-switch {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
  }

  @include lower-mobile {
    &-switch {
      width: 100%;
      height: 36px;
    }
  }
}


.mobile-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding: 0 20px 20px; 
}

.spinner {
  z-index: 2;

  width: 21px;
  height: 21px;

  animation: rotate 2s linear infinite;

  & .path {
    stroke: rgb(255 255 255);
    stroke-linecap: round;

    animation: dash 1.5s ease-in-out infinite;
  }

  &-container {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 100%;
    min-width: 21px;
    height: 100%;
    min-height: 21px;
  }
}

@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes dash {
  0% {
    stroke-dasharray: 1, 150;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -35;
  }
  100% {
    stroke-dasharray: 90, 150;
    stroke-dashoffset: -124;
  }
}

.filter-bottom-panel{
  display: flex;
  padding: 16px 20px 20px;
  background-color: var(--surface-front);
  width: 100%;
  justify-content: center;
  align-items: center;
  align-self: end;

  &-content-wrapper{
    display: flex;
    width: 100%;
    justify-content: end;
    align-items: center;
    gap: 12px;
  }
}
@import 'oelp-tokens/css/variables';

.input-field {
    box-sizing: border-box;
    &-label {
        width: 100%;
        color: var(--gray-400);

        &-text {
            display: inline-block;
            margin-bottom: 4px;
            padding-left: 8px;
            transition: all 0.12s ease-out;

            &.error {
                color: var(--validation-error-main);
            }
        }
        &:last-of-type {
            padding-bottom: 32px;
        }
        &:nth-of-type(n + 2) {
            padding-top: 12px;
        }
    }


    &-input {
        box-sizing: border-box;
        width: 100%;
        padding: 16px;
        color: var(--gray-800);
        font-family: var(--paragraph-desktop-x3-5-weight-1-font-family);
        font-weight: var(--paragraph-desktop-x3-5-weight-1-font-weight);
        line-height: var(--paragraph-desktop-x3-5-weight-1-line-height);
        font-size: var(--paragraph-desktop-x3-5-weight-1-font-size);
        letter-spacing: var(--paragraph-desktop-x3-5-weight-1-letter-spacing);
        font-style: normal;
        border: 1.4px solid var(--gray-0);
        border-radius: 8px;
        outline: none;
        transition: all 0.12s ease-out;
        cursor: text;

      &-wrapper {
            position: relative;
        }

        &-icon-password {
            position: absolute;
            top: 0;
            right: 16px;
            width: 24px;
            height: 100%;
            background-repeat: no-repeat;
            background-position: center;
            background-size: contain;
            cursor: pointer;

            &-show {
                background-image: url(./icons/FormPasswordEyeHide.svg);
            }
            &-hide {
                background-image: url(./icons/FormPasswordEyeShow.svg);
            }
        }

        &.error {
            border: 1.4px solid var(--validation-error-main);
        }

        &.warning {
            border: 1.4px solid $validation-warning-main;
        }

        .error {
            border: 1.4px solid var(--validation-error-main);
        }

        .warning {
            border: 1.4px solid $validation-warning-main;
        }

    }
}
